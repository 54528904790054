'use strict';

(function () {
    'use strict';

    RoleCtrl.$inject = ['$rootScope', '$scope', '$timeout', '$controller', 'CustomersService', 'RolesService', 'params'];
    angular.module('roles').controller('RoleCtrl', RoleCtrl);

    function RoleCtrl($rootScope, $scope, $timeout, $controller, CustomersService, RolesService, params) {
        var vm = this;
        vm.ctrlName = 'RoleCtrl';

        vm.ready = function () {
            $timeout(function () {
                vm.createFields();
            }, 0);
        };

        $controller('CreateBaseController', {
            vm: vm,
            $scope: $scope,
            service: RolesService,
            listPage: 'roles',
            nameModel: 'un privilège',
            params: params
        });

        vm.createFields = function () {
            vm.fields = [{
                key: 'name',
                type: 'input', // text

                templateOptions: {
                    label: 'Nom du privilège',
                    required: true
                }
            }, {
                key: 'description',
                type: 'input', // text

                templateOptions: {
                    label: 'Description'
                }
            }, {
                "type": "textarea",
                "key": "note",
                "templateOptions": {
                    "placeholder": "...",
                    "label": "Commentaires",
                    "rows": 4,
                    "cols": 15
                }
            }];
        };
    }
})();