/**
 * Created by sguilly on 27/05/15.
 */
'use strict';

angular.module('common').factory('mySocket', function () /*socketFactory, ENV*/{

    ////console.log('create websocket');
    //var myIoSocket = io.connect(ENV[window.__env].apiEndpoint);
    //
    //var mySocket = socketFactory({
    //  ioSocket: myIoSocket
    //});
    //
    //return mySocket;

    return {};
}).provider('Logger', [function () {

    var isEnabledConsole = true;
    var isEnabledSocket = false;

    this.enabledConsole = function (_isEnabled) {
        isEnabledConsole = !!_isEnabled;
    };

    this.enabledSocket = function (_isEnabled) {
        isEnabledSocket = !!_isEnabled;
    };

    this.$get = ['mySocket', function (mySocket) {
        var Logger = function Logger(context, userId) {
            this.context = context;

            if (userId) {
                this.userId = userId;
            }

            this.level = 6;
        };

        Logger.getInstance = function (context, userId) {
            return new Logger(context, userId);
        };
        Logger.supplant = function (str, o) {
            return str.replace(/\{([^{}]*)\}/g, function (a, b) {
                var r = o[b];
                return typeof r === 'string' || typeof r === 'number' ? r : a;
            });
        };
        Logger.getFormattedTimestamp = function (date) {
            return Logger.supplant('{0}:{1}:{2}:{3}', [date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()]);
        };
        Logger.prototype = {
            _log: function _log(originalFn, args) {
                if (!isEnabledConsole && !isEnabledSocket) {
                    return;
                }

                var now = Logger.getFormattedTimestamp(new Date());
                var message = '',
                    supplantData = [];
                switch (args.length) {
                    case 1:
                        message = Logger.supplant('{0} - {1}: {2}', [now, this.context, args[0]]);
                        break;
                    case 3:
                        supplantData = args[2];
                        message = Logger.supplant('{0} - {1}::{2}(\'{3}\')', [now, this.context, args[0], args[1]]);
                        break;
                    case 2:
                        if (typeof args[1] === 'string') {
                            message = Logger.supplant('{0} - {1}::{2}(\'{3}\')', [now, this.context, args[0], args[1]]);
                        } else {
                            supplantData = args[1];
                            message = Logger.supplant('{0} - {1}: {2}', [now, this.context, args[0]]);
                        }
                        break;
                }

                var log = Logger.supplant(message, supplantData);

                if (isEnabledConsole) {
                    console.log(originalFn + ' (' + now + ') ' + this.context, args[0], args[1]);
                }

                if (isEnabledSocket) {
                    var obj = {};
                    obj.timestamp = new Date();
                    obj.level = originalFn;
                    obj.context = this.context;

                    if (this.userId) {
                        obj.userId = this.userId;
                    }

                    obj.args0 = args[0];
                    obj.args1 = args[1];

                    mySocket.emit('log', obj);
                }
            },
            trace: function trace() {
                if (this.level >= 5) {
                    this._log('trace', arguments);
                }
            },
            info: function info() {
                if (this.level >= 4) {
                    this._log('info', arguments);
                }
            },
            warn: function warn() {
                if (this.level >= 3) {
                    this._log('warn', arguments);
                }
            },
            error: function error() {
                if (this.level >= 2) {
                    this._log('debug', arguments);
                }
            },
            fatal: function fatal() {
                if (this.level >= 1) {
                    this._log('error', arguments);
                }
            },
            setLevel: function setLevel(value) {
                this.level = value;
            }
        };
        return Logger;
    }];
}]);