"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  "use strict";

  var moduleName = "common";
  var componentName = "timePicker";

  var bindings = {
    time: "=",
    hideButton: "=?",
    dataPlacement: "=?",
    onChange: "&?"
  };

  var templateUrl = "app/common/component/timePicker/timePicker.html";

  var Controller = function () {
    /** @ngInject */
    function Controller($scope, $element, $timeout) {
      _classCallCheck(this, Controller);

      this.$scope = $scope;
      this.$timeout = $timeout;
      this.element = $($element);
    }

    _createClass(Controller, [{
      key: "$onInit",
      value: function $onInit() {
        var _this = this;

        if (!this.time) {
          this.time = "00:00";
        }

        if (!this.dataPlacement) {
          this.dataPlacement = "bottom";
        }

        this.element.clockpicker({
          donetext: "Valider",
          autoclose: true,
          afterDone: function afterDone() {
            _this.$timeout(function () {
              _this.time = _this.element.find("#time").val();

              console.log("new time", _this.time);
              if (_this.onChange) {
                _this.onChange({ value: _this.time });
              }
            });
          }
        });

        if (this.time) {
          this.element.find("#time").attr("value", this.time);
        }

        this.$scope.$watch(function () {
          return _this.time;
        }, function () {
          _this.element.find("#time").attr("value", _this.time);
        });
      }
    }, {
      key: "$onChanges",
      value: function $onChanges(what) {}
    }]);

    return Controller;
  }();

  angular.module(moduleName).component(componentName, {
    templateUrl: templateUrl,
    bindings: bindings,
    controller: Controller
  });
})();