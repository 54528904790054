'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('files').config(config);

    function config($stateProvider) {

        $stateProvider.state('files', {
            url: '/files',
            // templateUrl: 'app/pages/files/files-manager.html',
            // controller: 'GroupsCtrl',
            // controllerAs: 'vm'
            component: 'filesManager'
        });
    }
})();