"use strict";

/*global angular*/

(function () {
    "use strict";

    var log = debug('index:run');

    angular.module("app").run(function ($rootScope, $location) {
        log("run index");

        // agGrid.LicenseManager.prototype.getLicenseDetails.GridgetLicenseDetails = function(licenseKey) {

        //     log('GridgetLicenseDetails')
        //     const { md5, license } = agGrid.LicenseManager.extractLicenseComponents(licenseKey);
        //     let valid = true;

        //     let expiry;
        //     if (valid) {
        //         expiry = agGrid.LicenseManager.extractExpiry(license);
        //         valid = !isNaN(expiry.getTime());
        //     }

        //     return {
        //         licenseKey,
        //         valid,
        //         expiry: valid ? ag.LicenseManager.formatDate(expiry) : null
        //     }
        // }

        // agGrid.LicenseManager.setLicenseKey("");
        //agGrid.LicenseManager.setLicenseKey("ag-Grid_Evaluation_License_Not_For_Production_1Devs11_October_2017__MTUwNzY3NjQwMDAwMA==c5333f4e176c2db097a653f3f412ae15");

        $rootScope.$selectedCustomerId = localStorage.getItem("$selectedCustomerId");

        $rootScope.$version = "2.0.185";
        $rootScope.$env = "prod";
        if (window.location.href.includes("localhost")) {
            $rootScope.$version += ".dev";
            $rootScope.$env = "dev";
        } else if (window.location.href.includes("-beta")) {
            $rootScope.$version += ".beta";
            $rootScope.$env = "beta";
        } else if (window.location.href.includes("-test")) {
            $rootScope.$version += ".test";
            $rootScope.$env = "test";
        }

        //if (!localStorage.debug) {
        if (window.location.href.indexOf("localhost") != -1) {
            //localStorage.debug = "pages.*,model.*,services.*";
            //localStorage.debug = "pages:damages,model.*";

            localStorage.debug = "";
        } else {
            localStorage.debug = "";
        }
        //}

        // Customize notiflix
        Notiflix.Loading.init({
            backgroundColor: 'rgba(0,0,0,0.8)',
            svgColor: '#187EAE',
            clickToClose: true,
            messageMaxLength: 50
        });
        Notiflix.Notify.init({
            position: "center-center",
            clickToClose: true,
            timeout: 2000,
            success: {
                background: '#97A164',
                textColor: '#093155'
            },
            failure: {
                background: '#F9A159',
                textColor: '#093155'
            },
            warning: {
                background: '#F9A159',
                textColor: '#093155'
            },
            info: {
                background: '#2699CA',
                textColor: '#fff'
            }
        });

        log("end of run index");
    });
})();