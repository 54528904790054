"use strict";

(function () {
    "use strict";

    angular.module("app").config(["$compileProvider", function ($compileProvider) {

        var log = debug('config:app');

        log('enter');

        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|local|data|chrome-extension|file|javascript):/);
        $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|local|data|chrome-extension|file|javascript):/);
    }]);

    angular.module("FileManagerApp").config(["fileManagerConfigProvider", function (config) {

        var log = debug('config:FileManagerApp');

        log('enter');

        log("config", config);

        var apiPath = "http://localhost:5000";

        var defaults = config.$get();
        config.set({
            // appName: 'angular-filemanager',
            basePath: "/",
            defaultLang: "fr",
            multiLang: false,

            copyUrl: apiPath + "/copy", // where {/files} is the mount path of this module.
            createFolderUrl: apiPath + "/createFolder",
            downloadFileUrl: apiPath + "/download",
            editUrl: apiPath + "/edit",
            removeUrl: apiPath + "/remove",
            renameUrl: apiPath + "/rename",
            uploadUrl: apiPath + "/upload",
            getContentUrl: apiPath + "/getContent",
            listUrl: apiPath + "/list",
            // pickCallback: function(item) {
            //     var msg = 'Picked %s "%s" for external use'
            //         .replace('%s', item.type)
            //         .replace('%s', item.fullPath());
            //     window.alert(msg);
            // },

            allowedActions: angular.extend(defaults.allowedActions, {
                remove: false,
                compress: false
            })
        });
    }]);
})();