'use strict';

/**
 * Created by sguilly on 23/08/16.
 */
(function () {
    'use strict';

    var pages = angular.module('pages');

    pages.config(['$provide', function ($provide) {

        var log = debug('config:pages');

        log('enter');
    }]);
})();