'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('courses').config(config);

    function config($stateProvider) {
        $stateProvider.state('courses', {
            url: '/courses',
            templateUrl: 'app/pages/courses/courses.html',
            controller: 'CoursesCtrl',
            controllerAs: 'vm'
        }).state('course', {
            url: '/course/:id',
            templateUrl: 'app/pages/courses/course.html',
            controller: 'CourseCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();