'use strict';

/**
 * Created by sguilly on 04/10/16.
 */
(function () {
  'use strict';

  /* @ngdoc object
   * @name pages
   * @description
   *
   */

  angular.module('models', ['ngResource']);
})();