"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
    "use strict";

    var moduleName = "emailHistory.displayInformations";
    var componentName = "emailHistoryInfo";

    var bindings = {
        resolve: "<?",
        id: "<?"
    };

    var templateUrl = "app/pages/emailHistory/displayInformations/displayInformations.html";

    var Controller = function () {
        /** @ngInject */
        function Controller($scope, $timeout, $window, $sce, EmailHistoryService) {
            _classCallCheck(this, Controller);

            console.log(moduleName + "/" + componentName);

            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$window = $window;
            this.$sce = $sce;

            this.EmailHistoryService = EmailHistoryService;
        }

        _createClass(Controller, [{
            key: "close",
            value: function close() {
                console.log("$scope", this.$scope);

                if (this.$scope.$parent) {
                    this.$scope.$parent.$dismiss();
                }
            }
        }, {
            key: "trustAsHtml",
            value: function trustAsHtml(html) {
                return this.$sce.trustAsHtml(html.replace("margin: 0 !important;", "inherit"));
            }
        }, {
            key: "$onInit",
            value: function $onInit() {
                var _this = this;

                if (this.resolve && this.resolve.params && this.resolve.params.id) {
                    this.id = this.resolve.params.id;
                }

                console.log("depa info id=", this.id);

                this.EmailHistoryService.findById({ id: this.id.replace('$', '') }).then(function (emailHistory) {
                    _this.$timeout(function () {
                        _this.emailHistory = emailHistory;
                    }, 10);
                });
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        //transclude: true,
        controller: Controller
    });
})();