'use strict';

(function () {
    'use strict';

    Ctrl.$inject = ['vm', 'UsersService', 'GroupsService', 'moment'];
    angular.module('depa').controller('DepasCtrl.reminder.export', Ctrl);

    function Ctrl(vm, UsersService, GroupsService, moment) {

        vm.reminderExport = function () {

            console.log('in export');

            var data = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = vm.rawData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var depa = _step.value;


                    if (depa.status === 'Effectué' || depa.status === 'Annulé') {
                        continue;
                    }

                    try {
                        if (depa.driver && depa.driver.defaultManagerId) {
                            depa.manager = UsersService.getById(depa.driver.defaultManagerId);
                        }

                        data.push({
                            'Sinistre Référence': depa.damageName,
                            'Sinistre Date': depa.damageDate ? moment(depa.damageDate).format('DD/MM/YYYY') : '',
                            'Groupe': depa.groupName,
                            'Nom Collaborateur': depa.driverName,
                            'Téléphone Collaborateur': depa.phone,
                            'Email Collaborateur': depa.email,
                            'Email RDV': depa.emailToUse,
                            'Relance 1': depa.emailReminderDate1 ? moment(depa.emailReminderDate1).format('DD/MM/YYYY') : '',
                            'Relance 2': depa.emailReminderDate2 ? moment(depa.emailReminderDate2).format('DD/MM/YYYY') : '',
                            'Relance 3': depa.emailReminderDate3 ? moment(depa.emailReminderDate3).format('DD/MM/YYYY') : '',
                            '1er export final': depa.emailReminderDateLast ? moment(depa.emailReminderDateLast).format('DD/MM/YYYY') : '',
                            'Commentaires': depa.note,
                            'Statut': depa.status,
                            'Nom Responsable': depa.manager && depa.manager.firstName && depa.manager.lastName ? depa.manager.lastName + ' ' + depa.manager.firstName : '',
                            'Téléphone Responsable': depa.manager && depa.manager.phone ? depa.manager.phone : '',
                            'Portable Responsable': depa.manager && depa.manager.cellularPhone ? depa.manager.cellularPhone : '',
                            'Email Responsable': depa.manager && depa.manager.email ? depa.manager.email : ''

                        });
                    } catch (error) {
                        console.log('depa export error', depa, error);
                    }
                }

                /* generate a worksheet */
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var ws = XLSX.utils.json_to_sheet(data);

            var columnsWidth = [];

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var key = _step2.value;

                    columnsWidth.push({ wch: key.length + 2 });
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            ws["!cols"] = columnsWidth;

            /* add to workbook */
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Relances Depas Non programmés");

            /* write workbook (use type 'binary') */
            var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

            /* generate a download */
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) {
                    view[i] = s.charCodeAt(i) & 0xFF;
                }return buf;
            }

            saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export relances depas.xlsx");
        };
    }
})();