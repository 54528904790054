'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('damages').config(config);

    function config($stateProvider) {
        $stateProvider.state('damages', {
            url: '/damages',
            templateUrl: 'app/pages/damages/damages.html',
            controller: 'DamagesCtrl',
            controllerAs: 'vm'
        }).state('damage', {
            url: '/damage/:id',
            templateUrl: 'app/pages/damages/damage.html',
            controller: 'DamageCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();