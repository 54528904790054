"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module("my-ag-grid").service("AgGridLocale", function () {
  var _that$fr;

  var that = this;

  that.fr = (_that$fr = {
    // for filter panel
    page: "Page",
    more: "Plus",
    to: "De",
    of: "à",
    next: "Suivant",
    last: "Le dernier",
    first: "Le premier",
    previous: "Le suivant",
    loadingOoo: "Chargement...",

    // for set filter
    selectAll: "Tous sélectionner",
    searchOoo: "Tous désélectionner",
    blanks: "Vide",
    notBlank: "Non vide",
    empty: "Vide",

    // for number filter and text filter
    filterOoo: "Votre filtre ...",
    applyFilter: "Appliquer le filtre",

    // for number filter
    equals: "Egale",
    notEqual: "Différent",
    lessThan: "Plus petit que",
    greaterThan: "Plus grand que",

    lessThanOrEqual: "Plus petit ou Egale à",
    greaterThanOrEqual: "Plus grand ou Egale à",
    inRange: "Dans l'intervalle",
    inRangeStart: "Depuis",
    inRangeEnd: "Jusqu'à",

    // for text filter
    contains: "Contient",
    startsWith: "Commence par",
    endsWith: "Se termine par",

    // the header of the default group column
    group: "Colonnes groupées",

    // tool panel
    columns: "Les colonnes",
    rowGroupColumns: "Les colonnes de pivot",
    rowGroupColumnsEmptyMessage: "Déposer ici une colonne pour grouper",
    valueColumns: "Valeur",
    pivotMode: "Le mode pivot",
    groups: "Les groupes",
    values: "Valeurs",
    pivots: "Les pivots",
    valueColumnsEmptyMessage: "Colonnes à aggréger",
    pivotColumnsEmptyMessage: "Colonnnes pour le pivot",

    // other
    noRowsToShow: "Pas de données à afficher",

    // enterprise menu
    pinColumn: "Fixer la colonne",
    valueAggregation: "Valeur agrégée",
    autosizeThiscolumn: "Dimensionner cette colonne",
    autosizeAllColumns: "Dimensionner toutes les colonnes",
    groupBy: "Grouper par",
    ungroupBy: "Dégrouper",
    resetColumns: "Restaurer la disposition",
    expandAll: "Ouvrir tous les groupes",
    collapseAll: "Fermer tous les groupes",
    toolPanel: "Panneau des outils",

    // enterprise menu pinning
    pinLeft: "Fixer à gauche",
    pinRight: "Fixer à droite",
    noPin: "Décrocher",

    // enterprise menu aggregation and status panel
    sum: "Somme",
    min: "Min",
    max: "Max"
  }, _defineProperty(_that$fr, "first", "Le premier"), _defineProperty(_that$fr, "last", "Le dernier"), _defineProperty(_that$fr, "none", "Aucun"), _defineProperty(_that$fr, "count", "Compteur"), _defineProperty(_that$fr, "average", "Moyenne"), _defineProperty(_that$fr, "copy", "Copier"), _defineProperty(_that$fr, "copyWithHeaders", "Copier avec les titres"), _defineProperty(_that$fr, "ctrlC", "Ctrl C"), _defineProperty(_that$fr, "paste", "Coller"), _defineProperty(_that$fr, "ctrlV", "Ctrl V"), _that$fr);
});