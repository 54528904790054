'use strict';

(function () {
    'use strict';

    angular.module('pages').config(['$httpProvider', function ($httpProvider) {

        $httpProvider.interceptors.push(['$q', '$location', '$rootScope', function ($q, $location, $rootScope) {
            return {
                responseError: function responseError(rejection) {
                    if (rejection.status == 401) {
                        //Now clearing the loopback values from client browser for safe logout...

                        console.log('Error 401');

                        $location.nextAfterLogin = $location.path();
                        $location.path('/login');

                        // $rootScope.$showBars = false
                    }
                    return $q.reject(rejection);
                }
            };
        }]);
    }]);
})();