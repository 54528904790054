"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

!function (t, e) {
  "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "object" == (typeof module === "undefined" ? "undefined" : _typeof(module)) ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) ? exports.stringSimilarity = e() : t.stringSimilarity = e();
}(window, function () {
  return function (t) {
    var e = {};function n(r) {
      if (e[r]) return e[r].exports;var o = e[r] = { i: r, l: !1, exports: {} };return t[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }return n.m = t, n.c = e, n.d = function (t, e, r) {
      n.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: r });
    }, n.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t, "__esModule", { value: !0 });
    }, n.t = function (t, e) {
      if (1 & e && (t = n(t)), 8 & e) return t;if (4 & e && "object" == (typeof t === "undefined" ? "undefined" : _typeof(t)) && t && t.__esModule) return t;var r = Object.create(null);if (n.r(r), Object.defineProperty(r, "default", { enumerable: !0, value: t }), 2 & e && "string" != typeof t) for (var o in t) {
        n.d(r, o, function (e) {
          return t[e];
        }.bind(null, o));
      }return r;
    }, n.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };return n.d(e, "a", e), e;
    }, n.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, n.p = "", n(n.s = 0);
  }([function (t, e) {
    function n(t, e) {
      if (t = t.replace(/\s+/g, ""), e = e.replace(/\s+/g, ""), !t.length && !e.length) return 1;if (!t.length || !e.length) return 0;if (t === e) return 1;if (1 === t.length && 1 === e.length) return 0;if (t.length < 2 || e.length < 2) return 0;var n = new Map();for (var _e = 0; _e < t.length - 1; _e++) {
        var _r = t.substring(_e, _e + 2),
            o = n.has(_r) ? n.get(_r) + 1 : 1;n.set(_r, o);
      }var r = 0;for (var _t = 0; _t < e.length - 1; _t++) {
        var _o = e.substring(_t, _t + 2),
            i = n.has(_o) ? n.get(_o) : 0;i > 0 && (n.set(_o, i - 1), r++);
      }return 2 * r / (t.length + e.length - 2);
    }t.exports = { compareTwoStrings: n, findBestMatch: function findBestMatch(t, e) {
        if (!function (t, e) {
          return "string" == typeof t && !!Array.isArray(e) && !!e.length && !e.find(function (t) {
            return "string" != typeof t;
          });
        }(t, e)) throw new Error("Bad arguments: First argument should be a string, second should be an array of strings");var r = [];var o = 0;for (var _i = 0; _i < e.length; _i++) {
          var u = e[_i],
              f = n(t, u);r.push({ target: u, rating: f }), f > r[o].rating && (o = _i);
        }var i = r[o];return { ratings: r, bestMatch: i, bestMatchIndex: o };
      } };
  }]);
});