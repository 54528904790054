'use strict';

/**
 * Created by sguilly on 04/10/16.
 */
(function () {
    'use strict';

    /* @ngdoc object
     * @name models
     * @description
     *
     */

    angular.module('my-formly', ['ui.bootstrap', 'ngSanitize', 'ui.select', 'nya.bootstrap.select', 'formly', 'formlyBootstrap', 'ngInputCurrency']);
})();