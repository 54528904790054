'use strict';

/**
 * Created by sguilly on 27/10/16.
 */
(function () {
    'use strict';

    angular.module('common').filter('dayName', [function () {
        return function (dayNumber) {
            //1 = January
            var monthNames = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
            return monthNames[(dayNumber + 6) % 7];
        };
    }]);
})();