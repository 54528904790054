'use strict';

(function () {
    'use strict';

    var moduleName = 'common';
    var componentName = 'datePicker';

    var bindings = {
        date: '=',
        onChange: '&?'
    };

    var templateUrl = 'app/common/component/bootstrapDatePicker/bootstrapDatePicker.html';

    Controller.$inject = ['$scope', '$element'];

    function Controller($scope, $element) {
        var vm = this;

        var datePicker = $element.datepicker({
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            todayHighlight: true,
            toggleActive: true,
            language: 'fr',
            format: "dd/mm/yyyy"
        });

        datePicker.on('changeDate', function (e) {
            console.log('event', e);
            vm.date = e.date;

            if (this.onChange) {

                console.log('emit change date');
                this.onChange({ value: vm.date });
            }
        });
    }

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();