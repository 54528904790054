'use strict';

(function () {
    'use strict';

    /* @ngdoc object
     * @name customers
     * @description
     *
     */

    angular.module('damage', ['ui.router', 'common', 'my-formly']);
})();