'use strict';

angular.module('models').service('GalleryService', function () {

    var that = this;

    // that.getUserTypesDoc = [{
    //         value: 'U1',
    //         name: 'Permis de conduire'
    //     }, {
    //         value: 'U2',
    //         name: 'Carte identitée'
    //     },
    //     {
    //         value: 'U3',
    //         name: 'Passport'
    //     },       
    //     {
    //         value: 'U4',
    //         name: 'Rapport d/accident'
    //     },
    //     {
    //         value: 'U5',
    //         name: 'Rapport de conduite'
    //     },
    //     {
    //         value: 'U6',
    //         name: 'Fiche de suivi'
    //     },
    //     {
    //         value: 'U7',
    //         name: 'Fiche d\'évaluation formation'
    //     },
    //     {
    //         value: 'U8',
    //         name: 'Titre pro de conducteur'
    //     },
    //     {
    //         value: 'U9',
    //         name: 'FIMO'
    //     },
    //     {
    //         value: 'U10',
    //         name: 'FCO'
    //     },
    //     {
    //         value: 'other',
    //         name: 'Autre'
    //     }
    // ]

    // that.getVehicleTypesDoc = [{
    //         value: 'V1',
    //         name: 'Carte grise'
    //     },
    //     {
    //         value: 'V2',
    //         name: 'Assurance'
    //     },
    //     {
    //         value: 'other',
    //         name: 'Autre'
    //     }
    // ]

    // that.getCourseTypesDoc = [{
    //         value: 'C1',
    //         name: 'Feuille de présence en formation'
    //     },
    //     {
    //         value: 'C2',
    //         name: 'Fiche de suivi des messages vus et acquis'
    //     },
    //     {
    //         value: 'C3',
    //         name: 'Evaluation'
    //     },
    //     {
    //         value: 'C4',
    //         name: 'Fiche données moteur'
    //     },
    //     {
    //         value: 'C5',
    //         name: 'Compte Rendu'
    //     },
    //     {
    //         value: 'C6',
    //         name: 'Attestation de fin de formation'
    //     },
    //     {
    //         value: 'C7',
    //         name: 'Convention de formation'
    //     },
    //     {
    //         value: 'C8',
    //         name: 'Devis'
    //     },
    //     {
    //         value: 'C9',
    //         name: 'Fichier fusion'
    //     },
    //     {
    //         value: 'C10',
    //         name: 'Tableau récapitulatif route'
    //     },
    //     {
    //         value: 'C11',
    //         name: 'Grille code de la route'
    //     },
    //     {
    //         value: 'C12',
    //         name: 'Emargement'
    //     },
    //     {
    //         value: 'C13',
    //         name: 'Fiche audit'
    //     },
    //     {
    //         value: 'other',
    //         name: 'Autre'
    //     }
    // ]

    // that.getDepaTypesDoc = [{
    //         value: 'D1',
    //         name: 'Constat amiable'
    //     },
    //     {
    //         value: 'D2',
    //         name: 'Fiche Entretien'
    //     },
    //     {
    //         value: 'D3',
    //         name: 'Rapport d\'accident'
    //     },
    //     {
    //         value: 'other',
    //         name: 'Autre'
    //     }
    // ]

    that.getDefaultTypesDoc = [{
        value: 'other',
        name: 'Autre'
    }];
});