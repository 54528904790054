'use strict';

/**
 * Created by sguilly on 04/10/16.
 */
(function () {
  'use strict';

  /* @ngdoc object
   * @name models
   * @description
   *
   */

  angular.module('menu', ['common', 'models']);
})();