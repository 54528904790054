'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {

    var moduleName = 'common';
    var componentName = 'fullCalendar';

    var bindings = {
        events: '<?',
        small: '<?',
        needToBeUpdate: '=?',
        onChange: '&?',
        defaultView: '=?',
        minTime: '=?',
        maxTime: '=?'
    };

    var Controller = function () {
        /** @ngInject */
        function Controller($log, $element, $scope, $timeout) {
            _classCallCheck(this, Controller);

            this.calendar = $($element).find('#calendar');

            this.$timeout = $timeout;
            this.$scope = $scope;

            this.debug = debug('components:fullCalendar');
        }

        _createClass(Controller, [{
            key: '$onInit',
            value: function $onInit() {
                var _this = this;

                this.$scope.$watch(function () {
                    return _this.needToBeUpdate ? _this.needToBeUpdate : false;
                }, function () {

                    _this.debug('need to render calendar');

                    _this.calendar.fullCalendar('removeEvents');
                    _this.calendar.fullCalendar('addEventSource', _this.events);

                    _this.calendar.fullCalendar('render');
                    _this.calendar.fullCalendar('rerenderEvents');
                    _this.needToBeUpdate = false;
                });

                if (this.small) {
                    this.calendar.addClass('fc-smallSize');

                    var header = {
                        left: '', //'today',
                        center: 'prev title next',
                        right: '' //'listDay,listWeek,month,agendaWeek,agendaDay'
                    };
                } else {
                    var header = {
                        left: 'today',
                        center: 'prev title next',
                        right: 'listDay,listWeek,month,agendaWeek,agendaDay'
                    };
                }

                if (!this.events) {
                    this.events = [];
                }

                var w = $(window).width();
                var h = $(window).height();

                var ratio = w / h * 1.3;

                this.calendar.fullCalendar({
                    //height: 400,

                    //theme: true,
                    aspectRatio: ratio > 1.35 ? ratio : 1.35,
                    events: this.events,
                    locale: 'fr',
                    header: header,

                    // customize the button names,
                    // otherwise they'd all just say "list"
                    views: {
                        listDay: { buttonText: 'Liste Journée' },
                        listWeek: { buttonText: 'Liste Semaine' }
                    },

                    theme: false,

                    editable: true,

                    defaultView: this.defaultView ? this.defaultView : 'month',
                    minTime: this.minTime,
                    maxTime: this.maxTime,
                    // defaultDate: '2017-08-12',
                    navLinks: false, // can click day/week names to navigate views

                    weekNumbers: true,

                    eventClick: function eventClick(calEvent, jsEvent, view) {

                        // this.debug('Event: ', event);
                        // this.debug('Coordinates: ' + jsEvent.pageX + ',' + jsEvent.pageY);
                        // this.debug('View: ' + view.name);

                        if (_this.onChange) {
                            _this.onChange({
                                value: {
                                    type: 'eventClick',
                                    calEvent: calEvent,
                                    jsEvent: jsEvent,
                                    view: view
                                }
                            });
                        }

                        // if (!calEvent.inactive) {
                        //     calEvent.inactive = true
                        // } else {
                        //     delete calEvent.inactive
                        // }

                        // this.calendar.fullCalendar('rerenderEvents')
                    },

                    eventRender: function eventRender(event, element) {

                        if (event.inactive) {
                            element.css({ 'color': 'black', 'background': 'repeating-linear-gradient( 45deg, ' + event.backgroundColor + ', ' + event.backgroundColor + ' 15px, #ff0000 5px, #ff0000 20px)' });
                        } else {
                            element.css({ 'color': 'black', 'background': event.backgroundColor });
                        }

                        // element.find('.fc-title').append('<span class="yourCSS">TOTO</span> ');
                    },

                    viewRender: function viewRender(view, element) {
                        // this.debug('View', view)
                        // this.debug('Element', element)


                    }

                });

                $(window).resize(function () {

                    var w = $(window).width();
                    var h = $(window).height();

                    var ratio = w / h * 1.3;

                    // this.debug('ratio=', ratio)

                    _this.calendar.fullCalendar('option', 'aspectRatio', ratio > 1.35 ? ratio : 1.35);
                });
            }
        }, {
            key: 'resize',
            value: function resize() {}
        }, {
            key: '$onChanges',
            value: function $onChanges(changes) {
                var _this2 = this;

                // this.debug('change in fullCalendar binding', changes)

                if (changes.events) {

                    // this.debug('reload events')

                    this.$timeout(function () {
                        _this2.calendar.fullCalendar('removeEvents');
                        _this2.calendar.fullCalendar('addEventSource', _this2.events);
                        _this2.calendar.fullCalendar('rerenderEvents');
                    }, 0);
                }

                this.calendar.fullCalendar('render');
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        template: "<div id='calendar'></div>",
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();