'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('users').config(config);

    function config($stateProvider) {
        $stateProvider.state('users_id', {
            url: '/users/:customerId',
            templateUrl: 'app/pages/users/users.html',
            controller: 'UsersCtrl',
            controllerAs: 'vm',
            title: 'Users'
        }).state('users', {
            url: '/users',
            templateUrl: 'app/pages/users/users.html',
            controller: 'UsersCtrl',
            controllerAs: 'vm',
            title: 'Users'
        }).state('user', {
            url: '/user/:id',
            templateUrl: 'app/pages/users/user.html',
            controller: 'UserCtrl',
            controllerAs: 'vm',
            title: 'User',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();