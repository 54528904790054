'use strict';

(function () {
    'use strict';

    angular.module('common').config(function ($provide, tmhDynamicLocaleProvider) {

        var log = debug('config:common');

        log('enter');

        tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.5.5/angular-locale_{{locale}}.js');

        // fix TextAngular Sanitize issue

        // $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions) {

        //     taOptions.forceTextAngularSanitize = false;

        //     return taOptions;
        // }]);

        $provide.decorator('$rootScope', ['$delegate', function ($delegate) {
            Object.defineProperty($delegate.constructor.prototype, '$bus', {
                get: function get() {
                    var self = this;

                    return {
                        subscribe: function subscribe() {
                            var sub = postal.subscribe.apply(postal, arguments);

                            self.$on('$destroy', function () {
                                sub.unsubscribe();
                            });
                        },
                        channel: postal.channel,
                        publish: postal.publish.bind(postal)
                    };
                },
                enumerable: false
            });

            return $delegate;
        }]);
    });
})();