'use strict';

(function () {
    'use strict';

    angular.module('common').directive('footerLine', tableLine);

    function tableLine() {
        var directive = {
            restrict: 'EA',
            scope: {

                filterRowsNumber: '=',
                pageSize: '='
            },
            template: '\n            \n                <div class="footer-table-flex">                    \n\n                    <div class="header-table-space"> Nombre de lignes : {{vm.filterRowsNumber}} </div>\n                    <div st-pagination="" st-items-by-page="vm.pageSize" st-displayed-pages="5" st-template="app/common/smartTable/pagination.html"></div>\n                    <div class="header-table-space"> <span>Nb de lignes par page</span> <select data-width="fit" data-container="body" title="Rows on page" selectpicker ng-model="vm.pageSize" ng-options="i for i in [5,10,15,20,25]" ng-change="vm.changeSize()"></div>\n                    \n                </div>          \n\n            ',
            controller: ['$scope', function ($scope) {
                var vm = this;

                localforage.getItem('pageSize', function (err, value) {

                    if (!err && typeof value == 'number') {
                        vm.pageSize = value;
                    } else {
                        vm.pageSize = 10;
                        vm.changeSize();
                    }

                    $scope.$apply();
                });

                vm.changeSize = function () {
                    console.log('change size');

                    localforage.setItem('pageSize', vm.pageSize, function (err) {
                        console.log(err);
                    });
                };
            }],
            controllerAs: 'vm',
            bindToController: true

        };
        return directive;
    }
})();