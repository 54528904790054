'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {

    var moduleName = 'files';
    var componentName = 'filesManager';

    var bindings = {};

    var templateUrl = 'app/pages/files/files-manager.html';

    var Controller = function () {
        /** @ngInject */
        function Controller($scope, $element, $ocLazyLoad) {
            _classCallCheck(this, Controller);

            this.$element = $element;
            this.$ocLazyLoad = $ocLazyLoad;
            this.$scope = $scope;
        }

        _createClass(Controller, [{
            key: 'refresh',
            value: function refresh() {}
        }, {
            key: '$onInit',
            value: function $onInit() {
                this.state = 'Loaded!';

                console.log('component loaded !!!', this.$element);

                // this.$ocLazyLoad.load([
                //     'https://raw.githubusercontent.com/joni2back/angular-filemanager/master/dist/angular-filemanager.min.js',
                //     'https://raw.githubusercontent.com/joni2back/angular-filemanager/master/dist/angular-filemanager.min.css'
                // ]).then(() => {

                //   setTimeout(()=>{
                //     this.libsLoad = true
                //   },500)

                //     this.refresh()

                // })

            }
        }, {
            key: '$onChanges',
            value: function $onChanges() {

                if (this.libsLoad) {
                    this.refresh();
                }
            }
        }]);

        return Controller;
    }();

    angular.module('files').component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();