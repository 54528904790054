'use strict';

(function () {
    'use strict';

    /* @ngdoc object
     * @name vehicles
     * @description
     *
     */

    angular.module('vehicles', ['ui.router', 'my-formly', 'vehicle.displayInformations', 'common']);
})();