'use strict';

/**
 * @author v.lugovksy
 * created on 16.12.2015
 */

(function () {
    'use strict';

    angular.module('common').directive('sparkline', sparkline);

    /** @ngInject */
    function sparkline() {
        return {
            restrict: 'E',
            template: "<div></div>",
            controller: SparklineCtrl,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                data: '=',
                min: '=?',
                max: '=?'
            },
            link: function link(scope, el, attr, vm) {
                console.log('el.width=', el[0].getBoundingClientRect());

                Highcharts.chart(el[0], {
                    chart: {
                        type: 'area',
                        height: 65,
                        width: 150,
                        //margin: [2, 0, 2, 0],
                        // style: {
                        //     overflow: 'visible'
                        // },

                        // small optimalization, saves 1-2 ms each sparkline
                        skipClone: true
                    },
                    title: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: { // don't display the dummy year
                            month: '%e. %b',
                            year: '%b'
                        },
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false
                        },
                        min: vm.min ? vm.min : 0,
                        max: vm.max ? vm.max : 100
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: 'N={point.y}'
                    },

                    plotOptions: {
                        area: {
                            stacking: 'normal',
                            marker: {
                                //enabled: false
                                radius: 2
                            }

                        },

                        line: {},
                        series: {
                            animation: false,
                            lineWidth: 1,
                            shadow: false,

                            // states: {
                            //     hover: {
                            //         lineWidth: 1
                            //     }
                            // },
                            // marker: {
                            //     radius: 3,
                            //     states: {
                            //         hover: {
                            //             radius: 2
                            //         }
                            //     }
                            // },


                            fillOpacity: 0.25
                        }
                    },

                    series: vm.data

                });
            }
        };
    }

    function SparklineCtrl() {

        var vm = this;

        //console.log('vm.container=',vm.container)
        //console.log('-> vm.data=',vm.data)
    }
})();