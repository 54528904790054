"use strict";

(function () {
  "use strict";

  Ctrl.$inject = ["vm", "moment"];
  angular.module("users").controller("UsersCtrl.export", Ctrl);

  function Ctrl(vm, moment) {
    vm.export = function () {
      console.log("in export");

      var data = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = vm.rawData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var user = _step.value;

          try {
            var row = {
              Groupes: user.groupName,
              Role: user.roleName,
              Prénom: user.firstName,
              Nom: user.lastName,
              Actif: user.active ? "OUI" : "NON",
              "À vérifier": user.toCheck ? "OUI" : "",
              Matricule: user.trueInternalNumber ? user.trueInternalNumber : user.internalNumber,
              Email: user.email,
              "Téléphone fixe": user.phone,
              "Téléphone portable": user.cellularPhone,
              "Année de naissance": user.yearOfBirth ? moment(user.yearOfBirth).format("YYYY") : "",
              "Date embauche": user.startDate ? moment(user.startDate).format("DD/MM/YYYY") : "",
              "Date sortie": user.endDate ? moment(user.endDate).format("DD/MM/YYYY") : "",
              Titre: user.positionTitle,
              Fonctions: user.positions ? user.positions.join(", ") : "",
              "Nb sinistre sur 12 mois": user.nbDamages,
              "Nb sinistre responsable sur 12 mois": user.nbDispute100,
              "Nb sinistre 50/50 sur 12 mois": user.nbDispute50,
              "Nb sinistre non responsable sur 12 mois": user.nbDispute0,
              "Date dernier sinistre": user.lastDamageDate ? moment(user.lastDamageDate).format("DD/MM/YYYY") : "",
              "Nb DEPA effectués sur 12 mois": user.nbDepas,
              "Nb RDV manqués sur 12 mois": user.nbMissingDepas,
              "Date du DEPA du dernier sinistre": user.lastDepaDate ? moment(user.lastDepaDate).format("DD/MM/YYYY") : user.lastDepaId ? "Non Effectué" : "",
              "Nb formations": user.nbCourses,
              "Date dernière formation": user.lastCourseDate ? moment(user.lastCourseDate).format("DD/MM/YYYY") : "",
              Contrat: user.contractName,
              "Société d'intérim": user.externalCompany,
              "Date validité permis": user.licenceValidityDate ? moment(user.licenceValidityDate).format("DD/MM/YYYY") : "",
              "Zone de travail": user.areaName,
              Département: user.departmentName,
              Commentaires: user.note
            };
            row[vm.defaultManagerLabel] = user.managerName;
            if (!vm.rhManagerHidden) {
              row[vm.rhManagerLabel] = user.rhManagerName;
            }
            if (!vm.qseManagerHidden) {
              row[vm.qseManagerLabel] = user.qseManagerName;
            }
            if (!vm.fleetManagerHidden) {
              row[vm.fleetManagerLabel] = user.fleetManagerName;
            }
            if (!vm.other1ManagerHidden) {
              row[vm.other1ManagerLabel] = user.other1ManagerName;
            }
            if (!vm.other2ManagerHidden) {
              row[vm.other2ManagerLabel] = user.other2ManagerName;
            }
            data.push(row);
          } catch (error) {
            console.log("user export error", user, error);
          }
        }

        /* generate a worksheet */
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var ws = XLSX.utils.json_to_sheet(data);

      var columnsWidth = [];

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var key = _step2.value;

          columnsWidth.push({ wch: key.length + 2 });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      ws["!cols"] = columnsWidth;

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Collaborateurs");

      /* write workbook (use type 'binary') */
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      /* generate a download */
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }return buf;
      }

      saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export collaborateurs.xlsx");
    };
  }
})();