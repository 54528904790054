"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  var moduleName = "common";
  var componentName = "bsDatePicker";

  var bindings = {
    date: "=",
    onChange: "&?"
  };

  var templateUrl = "app/common/component/bootstrapDatePicker/bootstrapDatePicker.html";

  var Controller = function () {
    /** @ngInject */
    function Controller($log, $element, $scope, $timeout) {
      _classCallCheck(this, Controller);

      this.$element = $element;
      this.$scope = $scope;
      this.$timeout = $timeout;
    }

    _createClass(Controller, [{
      key: "clean",
      value: function clean($event) {
        console.log("clean");
        $event.stopPropagation();

        this.$element.datepicker("setDate", "");
        this.date = "";
      }
    }, {
      key: "$onInit",
      value: function $onInit() {
        var _this = this;

        if (this.date) {
          this.date = new Date(this.date);
        }

        var datePicker = this.$element.datepicker({
          keyboardNavigation: false,
          forceParse: false,
          calendarWeeks: true,
          autoclose: true,
          todayHighlight: true,
          toggleActive: true,
          language: "fr",
          format: "dd/mm/yyyy"
        });

        this.$element.datepicker("setDate", this.date);

        this.$scope.$watch(function () {
          return _this.date;
        }, function () {
          // console.log('date change from parent', this.date)
          _this.updateFromParent = true;
          _this.$element.datepicker("setDate", _this.date);
        });

        datePicker.on("changeDate", function (e) {
          if (_this.updateFromParent === true) {
            _this.updateFromParent = false;
          } else {
            _this.$timeout(function () {
              _this.date = e.date;
              if (_this.onChange) {
                console.log("emit change date", _this.date);
                _this.onChange({ value: _this.date });
              }
            }, 0);
          }
        });
      }
    }]);

    return Controller;
  }();

  angular.module(moduleName).component(componentName, {
    templateUrl: templateUrl,
    bindings: bindings,
    transclude: true,
    controller: Controller
  });
})();