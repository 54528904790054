'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('home').config(config);

    function config($stateProvider) {
        $stateProvider.state('home', {
            url: '/home',
            templateUrl: 'app/pages/home/home.html',
            controller: 'HomeCtrl',
            controllerAs: 'vm'
        });
    }
})();