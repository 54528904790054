'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('schedules').config(config);

    function config($stateProvider) {
        $stateProvider.state('schedules', {
            url: '/schedules',
            templateUrl: 'app/pages/schedules/schedules.html',
            controller: 'SchedulesCtrl',
            controllerAs: 'vm'
        }).state('schedule', {
            url: '/schedule/:id',
            templateUrl: 'app/pages/schedules/schedule.html',
            controller: 'ScheduleCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();