'use strict';

(function () {
    'use strict';

    angular.module('files', []);

    // angular.module('FileManagerApp').config(['fileManagerConfigProvider', function(config) {
    //     var defaults = config.$get();
    //     config.set({
    //         appName: 'angular-filemanager',
    //         basePath: '/my/custom/path',
    //         pickCallback: function(item) {
    //             var msg = 'Picked %s "%s" for external use'
    //                 .replace('%s', item.type)
    //                 .replace('%s', item.fullPath());
    //             window.alert(msg);
    //         },

    //         allowedActions: angular.extend(defaults.allowedActions, {
    //             pickFiles: true,
    //             pickFolders: false,
    //         }),
    //     });
    // }]);


    // https: //angular-filemanager.zendelsolutions.com/bridges/php/handler.php
})();