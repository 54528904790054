'use strict';

/**
 * Created by sguilly on 03/11/16.
 */

(function () {
    'use strict';

    angular.module('common').filter('custom-table-filter', function () {
        return function (array, expression) {

            //console.log('filter table');

            if (expression.$) {
                expression = expression.$.toLowerCase();
            } else {
                return array;
            }

            if (expression.length < 1) {
                return array;
            } else expression = expression.split(',');

            //console.log('expression', expression.length);


            var newArray = array.filter(function (val, index) {
                //console.log('expression', expression)

                // if (index === 0) {
                //     console.log(expression, val);
                // } else {
                //     //return false
                // }

                var result = 0;

                for (var key in val) {
                    var value = val[key];

                    if (value) {

                        if (key === 'from' || key === 'to') {
                            value = (value.poi.label + value.poi.name).toLowerCase();
                        } else {
                            value = value.toString().toLowerCase();
                        }

                        for (var i = 0; i < expression.length; i++) {
                            var match = value.indexOf(expression[i]) > -1 ? 1 : 0;

                            if (match === 1) {
                                //console.log('find ' + expression[i] + ' in key ' + key, value)
                            }

                            result += match;
                        }

                        if (result === expression.length) {
                            //console.log('found val', val)
                            return true;
                        }

                        //console.log('result=' + result);
                    }
                }

                //console.log('not found', val);
                return false;
            });

            return newArray;
        };
    });
})();