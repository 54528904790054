'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
    'use strict';

    var moduleName = 'common';
    var componentName = 'selectItems';

    var bindings = {
        items: "=",
        selectedIds: "="
    };

    var templateUrl = 'app/common/component/selectItems/selectItems.html';

    var Controller = function () {
        /** @ngInject */
        function Controller($scope, $uibModal) {
            _classCallCheck(this, Controller);

            console.log(moduleName + '/' + componentName);

            this.$scope = $scope;
            this.$uibModal = $uibModal;

            this.selectedTags = [];
        }

        _createClass(Controller, [{
            key: '$onInit',
            value: function $onInit() {
                var _this = this;

                if (!this.selectedIds) {
                    this.selectedIds = [];
                } else {

                    console.log('selectedIds', this.selectedIds);

                    this.$scope.$watch(function () {
                        return _this.items.length;
                    }, function () {

                        if (_this.items && _this.items.length > 0) {
                            console.log('--->', _this.items);
                            _.each(_this.selectedIds, function (value) {

                                var index = _.indexOf(_this.items, _.find(_this.items, { value: value }));

                                console.log('--->', value, index, _this.items[index]);

                                _this.items[index].selected = true;
                                _this.selectedTags.push(_this.items[index]);
                            });
                        }
                    });
                }
            }
        }, {
            key: 'add',
            value: function add($tag) {
                console.log('add', $tag);
                this.selectedIds.push($tag.value);

                var index = _.indexOf(this.items, _.find(this.items, { value: $tag.value }));
                this.items[index].selected = true;

                console.log('index=', index);
            }
        }, {
            key: 'remove',
            value: function remove($tag) {
                this.selectedIds = _.remove(this.selectedIds, function (n) {
                    return n != $tag.value;
                });

                var index = _.indexOf(this.items, _.find(this.items, { value: $tag.value }));
                this.items[index].selected = false;
            }
        }, {
            key: 'openTable',
            value: function openTable() {
                var _this2 = this;

                console.log('open modal');
                var modalInstance = this.$uibModal.open({
                    animation: false,
                    templateUrl: 'selectItemsTable.html',
                    size: 'sm',
                    backdrop: 'static',
                    controller: ['modalData', '$uibModalInstance', function (modalData, $uibModalInstance) {
                        var $ctrl = this;
                        $ctrl.items = modalData;

                        $ctrl.save = function () {
                            $uibModalInstance.close($ctrl.items);
                        };
                    }],
                    controllerAs: '$ctrl',
                    resolve: {
                        modalData: function modalData() {
                            return _this2.items;
                        }
                    }
                });

                modalInstance.result.then(function (value) {

                    _this2.selectedIds = [];
                    _this2.selectedTags = [];

                    _.each(_this2.items, function (item) {

                        if (item.selected) {
                            _this2.selectedIds.push(item.value);
                            _this2.selectedTags.push(item);
                        }
                    });
                }).catch(function (err) {});
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        //transclude: true,
        controller: Controller
    });
})();