"use strict";

angular.module("common").service("ModalService", function ($uibModal) {
  var that = this;

  that.open = function (name, _params) {
    return new Promise(function (resolve, reject) {
      if (_params) {
        _params.fromModal = true;
      } else {
        _params = { fromModal: true };
      }

      var controlerName = name.split("/")[1];

      var modalInstance = $uibModal.open({
        templateUrl: "app/pages/" + name + ".html",
        controller: controlerName.charAt(0).toUpperCase() + controlerName.slice(1) + "Ctrl",
        controllerAs: "vm",
        size: _params.size ? _params.size : "full",
        backdrop: 'static',
        keyboard: typeof _params.keyboard === "boolean" ? _params.keyboard : true,
        resolve: {
          params: function params() {
            return _params;
          }
        }
      });

      modalInstance.result.then(function (value) {
        console.log("modal close value=" + value);
        resolve(value);
      }).catch(function (err) {
        console.log("modal close with err=", err);
        if (err && err.toString() === "escape key press") {
          resolve(null);
        } else {
          reject();
        }
      });
    });
  };

  that.openComponent = function (name, _params2) {
    return new Promise(function (resolve, reject) {
      if (_params2) {
        _params2.fromModal = true;
      } else {
        _params2 = { fromModal: true };
      }

      var modalInstance = $uibModal.open({
        component: name,
        size: _params2.size ? _params2.size : "full",
        backdrop: 'static',
        keyboard: typeof _params2.keyboard === "boolean" ? _params2.keyboard : true,
        resolve: {
          params: function params() {
            return _params2;
          }
        }
      });

      modalInstance.result.then(function (value) {
        console.log("modal close value=" + value);
        resolve(value);
      }).catch(function (err) {
        console.log("modal close with err=", err);
        if (err && err.toString() === "escape key press") {
          resolve(null);
        } else {
          reject();
        }
      });
    });
  };
});