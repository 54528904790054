'use strict';

angular.module('models').service('TemplatesEmailsService', ['LbService', function (LbService) {

    var that = this;

    var params = {
        name: 'templatesHtmls',
        attributesForCache: ['name'],
        formatName: function formatName(element) {
            return element.name ? element.name : '???';
        }
        //defaultOrder: 'date DESC'
    };

    angular.extend(that, LbService.getModelBis(params));
}]);