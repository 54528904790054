'use strict';

(function () {
  'use strict';

  /* @ngdoc object
   * @name categories
   * @description
   *
   */

  angular.module('gallery', []);
})();