'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('import').config(config);

    function config($stateProvider) {
        $stateProvider.state('imports', {
            url: '/imports',
            templateUrl: 'app/pages/import/imports.html',
            controller: 'ImportsCtrl',
            controllerAs: 'vm'
        });
    }
})();