"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  "use strict";

  var componentName = "topBarSimpleUser";
  var bindings = {};

  var templateUrl = "app/menu/topBarSimpleUser/topBarSimpleUser.html";

  var Controller = function () {
    /** @ngInject */
    function Controller(AuthService, $rootScope, CustomersService, ModalService) {
      _classCallCheck(this, Controller);

      this.AuthService = AuthService;
      this.$rootScope = $rootScope;
      this.CustomersService = CustomersService;
      this.ModalService = ModalService;
    }

    _createClass(Controller, [{
      key: "$onInit",
      value: function $onInit() {
        var _this = this;

        if (this.$rootScope.$super) {
          console.log("user is $super", this.$rootScope.$user);

          this.CustomersService.getSelectArray().then(function (array) {
            _this.list = array;
          });
        }
      }
    }, {
      key: "emit",
      value: function emit() {
        console.log("emit pageTop:customer");
        if (this.$rootScope.$super) {
          //console.log('store this.$rootScope.$selectedCustomerId', this.$rootScope.$selectedCustomerId)
          localStorage.removeItem("last-depa-form");
          localStorage.setItem("$selectedCustomerId", this.$rootScope.$selectedCustomerId);
        }
      }
    }, {
      key: "logout",
      value: function logout() {
        console.log("toto directive");

        this.AuthService.logout();
      }
    }, {
      key: "myProfile",
      value: function myProfile() {
        this.ModalService.open("users/user", { id: this.$rootScope.$user.id }).then(function (id) {}).catch(function () {});
      }
    }, {
      key: "changePassword",
      value: function changePassword() {
        console.log("change password");

        this.ModalService.openComponent("changePassword", { id: "toto" }).then(function () {
          console.log("changePassword ok");
        }).catch(function (err) {
          console.log("err", err);
        });
      }
    }]);

    return Controller;
  }();

  angular.module("menu").component(componentName, {
    templateUrl: templateUrl,
    bindings: bindings,
    controller: Controller
  });
})();