'use strict';

/**
 * Created by sguilly on 19/10/16.
 */

(function () {
    'use strict';

    angular.module('common').directive('listRadio', listRadio);

    function listRadio() {
        var directive = {
            restrict: 'EA',
            template: '<div class="form-group uib-dropdown">' + '<label>{{vm.label}}</label>' + '<div class="pop-flex-center pop-radio-div form-control">' + '<span ng-repeat="item in vm.list" style="margin: 5px">' + '<label class="radio-inline custom-radio nowrap">' + '<input type="radio" name="inlineRadioOptions" ng-model="vm.ngModel" id="inlineRadio{{$index}}" value="{{item.id}}">' + '<span>{{item.name}}</span>' + '</div>' + '</label></span></div>',
            scope: {
                label: '=',
                list: '=',
                ngModel: '='
            },
            require: 'ngModel',
            controller: ListRadioCtrl,
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }

    function ListRadioCtrl() {
        var vm = this;
    }
})();