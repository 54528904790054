'use strict';

(function () {
    'use strict';

    /* @ngdoc object
     * @name login
     * @description
     *
     */

    angular.module('login', ['ui.router', 'common']);
})();