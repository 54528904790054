'use strict';

/**
 * Created by sguilly on 27/10/16.
 */
(function () {
    'use strict';

    angular.module('common').filter('true_false', function () {
        return function (text) {
            if (text) {
                return 'Oui';
            }
            return 'Non';
        };
    });
})();