"use strict";

angular.module("pages").controller("ListBaseController", function (vm, $scope, $state, service, params, $timeout, $rootScope, hotkeys, ModalService) {
  var log = debug("pages:listBaseController:" + vm.ctrlName);

  vm.resize = function () {
    //console.log('resize windows');

    var headerContainer = document.getElementById("headerContainer");
    var tabContainer = document.getElementById("tabContainer");

    if (headerContainer) {
      //console.log('headerContainer', headerContainer)
      var height = window.innerHeight && document.documentElement.clientHeight ? Math.min(window.innerHeight, document.documentElement.clientHeight) : window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName("body")[0].clientHeight;

      // console.log('headerContainer', height, headerContainer.offsetHeight, headerContainer.clientHeight)

      height = height - headerContainer.offsetHeight - 170;

      if (tabContainer) {
        height = height - 50;
      }

      var minHeight = 0;

      $timeout(function () {
        vm.height = height > minHeight ? height + "px" : minHeight + "px";
      }, 0);

      //console.log('vm.height', vm.height)
    }
  };

  $scope.$on("$destroy", function () {
    console.log("receive destroy");
  });

  $(window).resize(vm.resize);

  $timeout(function () {
    hotkeys.bindTo($scope).add({
      combo: "+",
      description: "Open create widows",
      callback: function callback() {
        // console.log('Open create widows')
        vm.create();
      }
    });
  }, 0);

  $timeout(function () {
    vm.resize();
  }, 500);

  // $timeout(() => {
  //     vm.resize()
  // }, 1000)

  // $timeout(() => {
  //     vm.resize()
  // }, 2000)

  vm.onEvent = function (type, data) {
    // console.log('events', type, data)

    switch (type) {
      case "edit":
        vm.edit(data);
        break;
      case "delete":
        vm.delete(data);
        break;
    }
  };

  vm.gridEvent = function (value) {
    if (value.type == "rowDoubleClicked") {
      if (vm.edit) {
        vm.edit(value.data);
      }
    } else if (value.type == "exportButton") {
      if (vm.export) {
        vm.export();
      }
    }
  };

  vm.displayInfo = function (data) {
    console.log("openModal", data);

    ModalService.openComponent(params.infoComponent, { id: data.id }).then(function () {
      console.log("close modal");
    }).catch(function (err) {
      console.log("err", err);
    });
  };

  vm.create = function (data) {
    if (!data) {
      data = {};
    }
    ModalService.open(params.editPage, data).then(function (value) {
      vm.refresh({ create: value });
    });
  };

  vm.edit = function (row) {
    // console.log('edit ' + editPage + '/' + row.id)

    // console.log('row ', row)

    ModalService.open(params.editPage, { id: row.id }).then(function (value) {
      vm.refresh({ edit: value });
    });
  };

  vm.delete = function (row) {
    // console.log('delete ' + row.id)

    service.deleteById(row.id).then(function () {
      Notiflix.Notify.success("La suppression a réussi");

      vm.refresh();
    }).catch(function (err) {
      Notiflix.Notify.failure("La suppression a échoué");
    });
  };
});