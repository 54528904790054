'use strict';

(function () {
    'use strict';

    var moduleName = 'common';
    var componentName = 'editList';

    var bindings = {
        //permissions: '@',
        list: "="
    };

    var templateUrl = 'app/common/component/editList/editList.html';

    Controller.$inject = ['$scope', '$element'];

    function Controller($scope, $element) {
        var vm = this;

        vm.add = function () {

            console.log('add');

            if (!vm.list) {
                vm.list = [];
            }
            vm.list.push('Nouveau');
        };

        vm.delete = function (index) {
            vm.list.splice(index, 1);
        };
    }

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        transclude: true,
        controller: Controller
    });

    angular.module(moduleName).directive("contenteditable", function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function link(scope, element, attrs, ngModel) {

                function read() {

                    ngModel.$setViewValue(element.html());
                }

                ngModel.$render = function () {
                    element.html(ngModel.$viewValue || "");
                };

                element.bind("blur keyup change", function () {
                    scope.$apply(read);
                });
            }
        };
    });
})();