'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('depa').config(config);

    function config($stateProvider) {
        $stateProvider.state('depas', {
            url: '/depas',
            templateUrl: 'app/pages/depas/depas.html',
            controller: 'DepasCtrl',
            controllerAs: 'vm',
            resolve: {

                // Example showing returning of custom made promise
                ready: function ready($q, $rootScope) {
                    var deferred = $q.defer();

                    if ($rootScope.$user) {

                        //console.log('$rootScope.$user -> ready1')
                        deferred.resolve('');
                    } else {
                        $rootScope.$watch(function () {
                            return $rootScope.$user;
                        }, function () {
                            //console.log('$rootScope.$user -> ready2', $rootScope.$user)

                            if ($rootScope.$user) {
                                deferred.resolve('');
                            }
                        }, true);
                    }

                    return deferred.promise;
                }
            }
        });
    }
})();