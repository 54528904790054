'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('vehicles').config(config);

    function config($stateProvider) {
        $stateProvider.state('vehicles_id', {
            url: '/vehicles/:customerId',
            templateUrl: 'app/pages/vehicles/vehicles.html',
            controller: 'VehiclesCtrl',
            controllerAs: 'vm',
            title: 'Vehicles'
        }).state('vehicles', {
            url: '/vehicles',
            templateUrl: 'app/pages/vehicles/vehicles.html',
            controller: 'VehiclesCtrl',
            controllerAs: 'vm',
            title: 'Vehicles'
        }).state('vehicle', {
            url: '/vehicle/:id',
            templateUrl: 'app/pages/vehicles/vehicle.html',
            controller: 'VehicleCtrl',
            controllerAs: 'vm',
            title: 'Vehicle',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();