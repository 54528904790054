'use strict';

angular.module('models').service('DamagesService', ['LbService', function (LbService) {

    var that = this;

    var params = {
        name: 'damages',
        //attributesForCache: ['name'],
        // formatName: function (element) {
        //     return element.name ? element.name : '???'
        // },
        defaultOrder: 'creationDate DESC'
    };

    angular.extend(that, LbService.getModelBis(params));

    // that.getNameById = function(id) {
    //     var o = that.getById(id)
    //     return o ? o.name : '???'
    // }

    that.informManager = function (damage, managerIdToInform) {
        return new Promise(function (resolve, reject) {
            try {
                that.remote("informManager", { damage: damage, managerIdToInform: managerIdToInform }).then(function (values) {
                    resolve(values);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    that.getDamageTypes = [{
        value: 'C1',
        name: 'Atelier Simulateur'
    }, {
        value: 'C2',
        name: 'Atelier Théorie'
    }, {
        value: 'C3',
        name: 'Ateliers multiples'
    }, {
        value: 'C4',
        name: 'Conduite sur route'
    }, {
        value: 'C5',
        name: 'Coaching individuel'
    }, {
        value: 'other',
        name: 'Autre'
    }];

    that.getDamageTypes = ['Atelier Simulateur', 'Atelier Théorie', 'Ateliers multiples', 'Conduite sur route', 'Coaching individuel', 'Autre'];
}]);