'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('customers').config(config);

    function config($stateProvider) {
        $stateProvider.state('customers', {
            url: '/customers',
            templateUrl: 'app/pages/customers/customers.html',
            controller: 'CustomersCtrl',
            controllerAs: 'vm',
            title: 'Customers'
            // sidebarMeta: {
            //     icon: 'ion-android-home',
            //     order: 0,
            // },
        }).state('customer', {
            url: '/customer/:id',
            templateUrl: 'app/pages/customers/customer.html',
            controller: 'CustomerCtrl',
            controllerAs: 'customer',
            title: 'Customer'
        }).state('new-customer', {
            url: '/customer',
            templateUrl: 'app/pages/customers/customer.html',
            controller: 'CustomerCtrl',
            controllerAs: 'customer',
            title: 'Customer',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();