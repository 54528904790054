/**
 * Created by sguilly on 04/10/16.
 */
'use strict';

angular.module('my-formly').config(['nyaBsConfigProvider', function (nyaBsConfigProvider) {

    var localizedText = {
        defaultNoneSelection: 'Pas d\'élément sélectionné',
        noSearchResult: 'Pas de correspondance',
        numberItemSelected: '%d sélectionné',
        selectAll: 'Tout sélectionner',
        deselectAll: 'Tout désélectionner'
    };

    nyaBsConfigProvider.setLocalizedText('fr-FR', localizedText);

    nyaBsConfigProvider.useLocale('fr-FR');
}]);