'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/**
 * Created by sguilly on 03/11/16.
 */

(function () {
    'use strict';

    angular.module('common').directive('searchWatchModel', function () {
        return {
            require: '^stTable',
            scope: {
                searchWatchModel: '=',
                stFilterRowsNumber: '='
            },
            link: function link(scope, ele, attr, ctrl) {
                var table = ctrl;

                scope.$watch(function () {
                    return ctrl.getFilteredCollection().length;
                }, function (val) {
                    scope.stFilterRowsNumber = val;
                });

                scope.$watch('searchWatchModel.length', function (val) {

                    var newFilter = '';

                    if (_typeof(scope.searchWatchModel) == 'object') {
                        if (scope.searchWatchModel && scope.searchWatchModel.length) {

                            for (var i = 0; i < scope.searchWatchModel.length; i++) {
                                if (i > 0) {
                                    newFilter += ',';
                                }
                                newFilter += scope.searchWatchModel[i].text;
                            }
                        }
                    } else {
                        newFilter = scope.searchWatchModel;
                    }

                    ctrl.search(newFilter);
                });
            }
        };
    });
})();