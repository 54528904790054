'use strict';

angular.module('common').controller('SelectCustomerController', function ($scope, CustomersService, ModalService) {

    $scope.refresh = function (id) {

        CustomersService.getSelectArray().then(function (array) {
            $scope.to.options = array;
        });
    };

    $scope.create = function () {

        console.log();
        ModalService.open('customers/customer', {}).then(function (id) {
            $scope.refresh();
        });
    };

    $scope.reset = function () {

        console.log('reset');

        $scope.model[$scope.options.key] = null;

        if ($scope.options.templateOptions.refresh) {
            $scope.options.templateOptions.refresh();
        }
    };

    $scope.edit = function () {

        ModalService.open('customers/customer', { id: id }).then(function (id) {
            $scope.refresh(id);
        });
    };

    $scope.refresh();
});