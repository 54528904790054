'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('actionHistorys').config(config);

    function config($stateProvider) {
        $stateProvider.state('actionHistories', {
            url: '/actionHistories',
            templateUrl: 'app/pages/actionHistories/actionHistories.html',
            controller: 'ActionHistorysCtrl',
            controllerAs: 'vm'
        });
    }
})();