'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('roles').config(config);

    function config($stateProvider) {
        $stateProvider.state('roles', {
            url: '/roles',
            templateUrl: 'app/pages/roles/roles.html',
            controller: 'RolesCtrl',
            controllerAs: 'vm'
        }).state('role', {
            url: '/role/:id',
            templateUrl: 'app/pages/roles/role.html',
            controller: 'RoleCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();