'use strict';

angular.module('models').service('RolesService', ['LbService', function (LbService) {

    var that = this;

    angular.extend(that, LbService.getModel('roles', ['name', 'description'], function (element) {
        return element.name;
    }));

    that.getSelectArray = function (customerId) {
        return new Promise(function (resolve, reject) {

            that.load(['name', 'description']).then(function (values) {
                var array = [];

                for (var index = 0; index < values.length; index++) {
                    var response = values[index];

                    if (['operator', 'super-operator', 'super-admin'].includes(response.name) && customerId !== "$592e962acef2545fb62f1a99") {
                        continue;
                    } else if (!['driver', 'operator', 'super-operator', 'super-admin'].includes(response.name) && customerId === "$592e962acef2545fb62f1a99") {
                        continue;
                    }

                    array.push({
                        name: response.description,
                        value: response.name
                    });
                }

                resolve(array);
            });
        });
    };
}]);