'use strict';

angular.module('models').service('DepasService', ['LbService', function (LbService) {

    var that = this;

    var params = {
        name: 'depas',
        attributesForCache: ['name'],
        formatName: function formatName(element) {
            return element.name ? element.name : '???';
        },
        defaultOrder: 'creationDate DESC'
    };

    angular.extend(that, LbService.getModelBis(params));
}]);