'use strict';

/**
 * Created by sguilly on 27/10/16.
 */
(function () {
    'use strict';

    angular.module('common').filter('monthName', [function () {
        return function (monthNumber) {
            //1 = January
            var monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
            return monthNames[monthNumber];
        };
    }]);
})();