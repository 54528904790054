'use strict';

/**
 * @author v.lugovksy
 * created on 16.12.2015
 */
(function () {
    'use strict';

    angular.module('common').directive('sl', function () {
        function link(scope, el, attr) {

            el = d3.select(el[0]);

            var svg = el;

            var data = scope.$eval(attr.data); //JSON.parse(attr.data);

            console.log('data spark line', data);

            if (data) {
                var min = attr.min !== undefined ? +attr.min : d3.min(data);
                var max = attr.max !== undefined ? +attr.max : d3.max(data);
                //el.text(''); // remove the original data text

                var r = attr.r || 0;
                var m = r;
                var w = svg.node().clientWidth;

                // var h = +getComputedStyle(el.node())['font-size'].replace('px','');
                // svg.attr({width: w, height: h});

                var h = svg.node().clientHeight;

                var x = d3.scale.linear().domain([0, data.length - 1]).range([m, w - m]);
                var y = d3.scale.linear().domain([min, max]).range([h - m, m]);

                var lines = svg.append('path').data(data).attr('d', 'M' + data.map(function (d, i) {

                    if (d > -1) {
                        return [x(i), y(d)];
                    }

                    return '';
                }).join('L'));

                var circles = svg.selectAll('circle').data(data).enter().append('circle').attr('r', function (d) {
                    return d > -1 ? r : 0;
                }).attr('cx', function (d, i) {
                    return x(i);
                }).attr('cy', function (d) {
                    if (d == -1) {
                        return y(0);
                    } else {
                        return y(d);
                    }
                }).style("fill", function (d) {
                    if (d == -1) {
                        return 'red';
                    } else {
                        return 'blue';
                    }
                });
            }
        }
        return {
            link: link,
            restrict: 'E',
            replace: true,
            template: '<svg ng-transclude class="sl"></svg>',
            transclude: true
        };
    });
})();