'use strict';

(function () {
    'use strict';

    /* @ngdoc object
     * @name customers
     * @description
     *
     */

    angular.module('vehicle', ['ui.router', 'common', 'angularMoment']);
})();