'use strict';

(function () {
    'use strict';

    angular.module('common').directive('tableActions', tableActions);

    function tableActions() {
        var directive = {
            restrict: 'EA',
            scope: {
                title: '=',
                labelButton: '=',
                cbButton: '='
            },
            template: '\n            <div class="row" style="margin-top: 20px; margin-bottom: 20px">\n                <div class="col-xs-6">\n                    <h1 style="margin: 10px">{{vm.title}}</h1>\n                </div>\n\n                <div class="col-xs-6">\n                    <button class="btn btn-default btn-with-icon pop-button" ng-click="vm.cbButton()"><i class="ion-plus pop-icon-button"></i>{{vm.labelButton}}</button>\n                </div>\n            </div>\n            ',
            controller: Controller,
            controllerAs: 'vm',
            bindToController: true

        };
        return directive;
    }

    function Controller() {
        var vm = this;
    }
})();