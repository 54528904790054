'use strict';

(function () {
    'use strict';

    RolesCtrl.$inject = ['$rootScope', '$scope', '$controller', 'CustomersService', 'UsersService', 'RolesService'];
    angular.module('roles').controller('RolesCtrl', RolesCtrl).component('roles', {
        templateUrl: 'app/pages/roles/roles.html',
        bindings: {
            resolve: "<?"
        },
        //transclude: true,
        controller: RolesCtrl
    });

    function RolesCtrl($rootScope, $scope, $controller, CustomersService, UsersService, RolesService) {
        var vm = this;
        vm.ctrlName = 'RolesCtrl';

        $controller('ListBaseController', {
            vm: vm,
            $scope: $scope,
            service: RolesService,
            params: {
                editPage: 'roles/role'
            }
        });

        vm.isSuperAdmin = ["super-admin"].indexOf($rootScope.$user.role) > -1 ? true : false;

        vm.close = function () {
            console.log("$scope", $scope);
            if ($scope.$parent) {
                $scope.$parent.$close();
            }
        };

        vm.refresh = function () {
            if (!vm.isSuperAdmin) {
                $rootScope.$broadcast('auth_not_authorized');
                return;
            }

            console.log('refresh roles');

            RolesService.load().then(function (roles) {

                vm.rawData = [];
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var role = _step.value;

                        vm.rawData.push(role);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                vm.displayedData = [].concat(vm.rowCollection);
            });
        };

        vm.refresh();
    }
})();