'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {

    var moduleName = 'common';
    var componentName = 'summernote';

    var bindings = {
        html: '=?',
        needToBeUpdate: '=?',
        onChange: '&?'
    };

    var Controller = function () {
        /** @ngInject */
        function Controller($log, $element, $scope, $timeout) {
            _classCallCheck(this, Controller);

            this.summernote = $element.find('#summernote');

            console.log('summernote=', this.summernote);

            this.$timeout = $timeout;
            this.$scope = $scope;
        }

        _createClass(Controller, [{
            key: '$onInit',
            value: function $onInit() {
                var _this = this;

                this.beautifyButton = function (context) {
                    var ui = $.summernote.ui;

                    // create button
                    var button = ui.button({
                        contents: '<i class="fa fa-puzzle-piece"/> Beautify',
                        tooltip: 'Beautify',
                        className: 'btn-codeview',
                        click: function click() {
                            var markupStr = context.code();
                            markupStr = html_beautify(markupStr);
                            context.code(markupStr);
                        }
                    });

                    return button.render(); // return button as jquery object
                };

                // this.saveButton = function(context) {
                //     var ui = $.summernote.ui;

                //     // create button
                //     var button = ui.button({
                //         contents: '<i class="fa fa-floppy-o"/> Sauvegarder',
                //         tooltip: 'Sauvegarder',
                //         className: 'btn-codeview',

                //         click: () => {

                //             console.log('context', context)
                //             var markupStr = context.code()
                //             markupStr = html_beautify(markupStr)
                //             context.code(markupStr)

                //             context.triggerEvent('save', markupStr);
                //         }
                //     });

                //     return button.render(); // return button as jquery object
                // }


                this.$scope.$watch(function () {
                    return _this.needToBeUpdate ? _this.needToBeUpdate : false;
                }, function () {

                    _this.summernote.summernote('code', _this.html);
                    _this.needToBeUpdate = false;
                });

                this.summernote.summernote({
                    height: 300,
                    focus: false,
                    toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']], ['fontface', ['fontname']], ['textsize', ['fontsize']], ['fontclr', ['color']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['height', ['height']], ['table', ['table']], ['insert', ['link', 'picture', 'video', 'hr']], ['view', ['codeview', 'beautify']]],

                    buttons: {
                        save: this.saveButton,
                        beautify: this.beautifyButton
                    },
                    hint: {
                        mentions: ['photos', 'logo', 'button.no', 'button.yes'],
                        match: /\B&(\w*)$/,
                        search: function search(keyword, callback) {
                            callback($.grep(this.mentions, function (item) {
                                return item.indexOf(keyword) == 0;
                            }));
                        },
                        content: function content(item) {

                            var value;
                            if (item == 'photos') {
                                var div = document.createElement('div');
                                div.innerHTML = "<p>{{#photos}} <img ng-src='http://checkfleetcar.s3.amazonaws.com/{{filePath}}'> {{/photos}}</p>";

                                value = div;
                            } else if (item == 'logo') {
                                var div = document.createElement('div');
                                div.innerHTML = "<img src=\"http://beta.checkcarfleet.com/admin/images/CheckCarFleet.png\" style=\"width: 273.724px; height: 42px;\">";

                                value = div;
                            } else if (item == 'button.yes') {
                                var div = document.createElement('div');
                                div.innerHTML = "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"background-color:#648f00; border-radius:15px;\"><tbody><tr><td align=\"center\" valign=\"middle\" style=\"color:#FFFFFF; font-family:Helvetica, Arial, sans-serif; font-size:16px; font-weight:bold; letter-spacing:-.5px; line-height:150%; padding-top:15px; padding-right:30px; padding-bottom:15px; padding-left:30px;\"><a href=\"http://beta.checkcarfleet.com/api/\" target=\"_blank\" style=\"color:#FFFFFF; text-decoration:none;\">OUI</a></td></tr></tbody></table>";
                                value = div;
                            } else if (item == 'button.no') {
                                var div = document.createElement('div');
                                div.innerHTML = "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"background-color:#ff2602; border-radius:15px;\"><tbody><tr><td align=\"center\" valign=\"middle\" style=\"color:#FFFFFF; font-family:Helvetica, Arial, sans-serif; font-size:16px; font-weight:bold; letter-spacing:-.5px; line-height:150%; padding-top:15px; padding-right:30px; padding-bottom:15px; padding-left:30px;\"><a href=\"http://beta.checkcarfleet.com/api/\" target=\"_blank\" style=\"color:#FFFFFF; text-decoration:none;\">NON</a></td></tr></tbody></table>";
                                value = div;
                            } else {
                                value = '{{' + item + '}}';
                            }

                            return value;
                        }
                    },
                    popover: {
                        image: [['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']], ['float', ['floatLeft', 'floatRight', 'floatNone']], ['custom', ['imageAttributes', 'imageShape']], ['remove', ['removeMedia']]]
                    },

                    callbacks: {
                        // onSave: (html) => {
                        //     this.onChange({ value: html })
                        // },

                        onChange: function onChange(contents, $editable) {
                            _this.$timeout(function () {
                                _this.html = contents;
                            }, 0);
                        }
                    }
                });

                this.summernote.summernote('code', this.html);
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        template: "<div id='summernote'></div>",
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();