"use strict";

angular.module("models").service("OperatorsService", ["LbService", function (LbService) {
  var that = this;

  var params = {
    name: "operators",
    customPath: "users",
    attributesForCache: ["lastName", "firstName", "calendarColor"],
    formatName: function formatName(element) {
      return element ? that.getName(element) : "???";
    },
    loadCache: function loadCache() {
      return that.remote("getOperators");
    }
    //defaultOrder: 'date DESC'
  };

  angular.extend(that, LbService.getModelBis(params));

  that.getName = function (o) {
    if (o) {
      var name = "";

      if (o.lastName) {
        name += o.lastName.toUpperCase() + " ";
      }

      if (o.firstName) {
        name += o.firstName.charAt(0).toUpperCase() + o.firstName.slice(1).toLowerCase();
      }
      return name;
    } else {
      return "N.C.";
    }
  };

  that.get = function (customerId) {
    return new Promise(function (resolve, reject) {
      try {
        if (customerId) {
          that.remote("getOperators", { data: { customerId: customerId } }).then(function (values) {
            resolve(values);
          });
        } else {
          that.remote("getOperators").then(function (values) {
            resolve(values);
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  that.getSelectArray = function () {
    return new Promise(function (resolve, reject) {
      that.get().then(function (values) {
        var array = [];

        for (var index = 0; index < values.length; index++) {
          var response = values[index];

          array.push({
            name: response.firstName + " " + response.lastName,
            value: "$" + response.id
          });
        }

        resolve(array);
      });
    });
  };
}]);