'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('login').config(config);

    function config($stateProvider) {
        $stateProvider.state('login', {
            url: '/login',
            templateUrl: 'app/pages/login/login.tpl.html',
            controller: 'LoginCtrl',
            controllerAs: 'login'
        });
        // .state('password', {
        //     url: '/password',
        //     component: 'changePassword'
        // })
    }
})();