'use strict';

angular.module('models').service('LalsService', ['LbService', function (LbService) {

    var that = this;

    var params = {
        name: 'lals',
        attributesForCache: ['name'],
        formatName: function formatName(element) {
            return element.name ? element.name : '???';
        }
        //defaultOrder: 'plannedStartDate'
    };

    angular.extend(that, LbService.getModelBis(params));
}]);