'use strict';

(function () {
    'use strict';

    /* @ngdoc object
     * @name users
     * @description
     *
     */

    angular.module('users', ['ui.router', 'my-formly', 'user.selectionUsers', 'user.displayInformations', 'common', 'bg.spreadit']);
})();