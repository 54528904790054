'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('gallery').config(config);

    function config($stateProvider) {
        $stateProvider.state('gallery', {
            url: '/gallery',
            templateUrl: 'app/pages/gallery/gallery.html',
            controller: 'GalleryCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();