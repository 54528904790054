'use strict';

(function () {
    'use strict';

    angular.module('common').directive('slotPicker2', slotPicker2);

    /** @ngInject */
    function slotPicker2() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directive/slotPicker2/slotPicker2.html',
            link: linkFunc,
            controller: SlotPickerCtrl,
            controllerAs: 'vm',
            bindToController: true,

            scope: {
                index: '=',
                header: '=',
                planning: '=',
                onSelect: '&'
            }
        };
    }

    function linkFunc(scope, el, attr, vm) {}

    function SlotPickerCtrl($scope, $element, $attrs, moment) {

        var vm = this;

        $scope.$watch(function () {
            //console.log('check=', vm.busy.length)
            return vm.planning.length;
        }, function (newValue) {

            console.log('new planning', vm.planning);

            vm.refresh();
        });

        vm.refresh = function () {

            console.log('refresh planning');

            for (var indexDay = 0; indexDay < vm.planning.length; indexDay++) {
                var element = vm.planning[indexDay];

                element.date = moment(element.date);
            }

            // var now = moment().startOf('day');

            // vm.hours = [];
            // vm.row = [];

            // vm.after = vm.after ? parseInt(vm.after) : 8;
            // vm.before = vm.before ? parseInt(vm.before) : 17;

            // vm.rows = vm.rows ? parseInt(vm.rows) : 7;
            // vm.duration = vm.duration ? parseInt(vm.duration) : 20;

            // vm.busySlots = angular.copy(vm.busy) ? vm.busy : []

            // console.log('vm=', vm);

            // for (var h = vm.after; h < vm.before; h++) {
            //     vm.hours.push(h);

            //     console.log('h', h);
            // }

            // for (var n = 0; n < vm.rows; n++) {
            //     vm.row[n] = {
            //         date: now.clone().add(n, 'days'),
            //         slots: []
            //     };

            //     var displayHour = vm.after;
            //     var displayMinute = 0;

            //     for (var s = 0; s < (vm.before - vm.after) * (60 / vm.duration); s++) {
            //         var start = displayHour + ':' + (displayMinute < 10 ? '0' + displayMinute : displayMinute);

            //         displayMinute += vm.duration;

            //         if (displayMinute > 59) {
            //             displayHour += 1;
            //             displayMinute = displayMinute % 60;
            //         }

            //         var end = displayHour + ':' + (displayMinute < 10 ? '0' + displayMinute : displayMinute);

            //         if (vm.row[n].date.isoWeekday() > 5) {
            //             vm.row[n].slots.push({
            //                 index: s,
            //                 active: -1,
            //                 tooltip: start + ' à ' + end
            //             });
            //         } else {
            //             vm.row[n].slots.push({
            //                 index: s,
            //                 active: 1,
            //                 tooltip: start + ' à ' + end
            //             });
            //         }
            //     }

            //     // var busy = [{start: moment('2016-12-15 14:30:00'), end:moment('2016-12-15 19:40:00')},
            //     //     {start: moment('2016-12-15 10:30:00'), end:moment('2016-12-15 12:00:00')}]
            // }

            // //console.log('check all slots', vm.busySlots)

            // for (var index = 0; index < vm.busySlots.length; index++) {

            //     console.log('slot=', slot)
            //     var slot = vm.busySlots[index];

            //     var nbDays = slot.start.diff(now, 'days');
            //     var nbHours = slot.start.diff(now, 'hours');

            //     console.log();
            //     console.log('nbHours=' + slot.start.diff(now, 'hours'));
            //     console.log('nbDays=' + nbDays);
            //     console.log(now.format('DD/MM/YYYY HH:mm'));
            //     console.log(slot.start.format('DD/MM/YYYY HH:mm'), '->', slot.end.format('DD/MM/YYYY HH:mm'));

            //     if (nbDays >= 0 && nbHours >= 0) {

            //         var indexStart = Math.trunc((slot.start.local().get('hour') * 60 + slot.start.get('minute') - vm.after * 60) / vm.duration);

            //         var indexEnd = Math.trunc((slot.end.local().get('hour') * 60 + slot.end.get('minute') - 1 - vm.after * 60) / vm.duration);

            //         if (indexStart < 0) {
            //             indexStart = 0;
            //         }

            //         if (indexEnd > vm.row[0].slots.length) {
            //             indexEnd = vm.row[0].slots.length - 1;
            //         }

            //         console.log('diff=', indexStart, indexEnd);

            //         for (var i = indexStart; i <= indexEnd; i++) {
            //             vm.row[nbDays].slots[i].active = 0;
            //         }
            //     }

            // }

            // console.log('vm.row', vm.row)

        };

        vm.select = function (slot) {

            console.log('slot in =', slot);
            // console.log('select in slotPicker=', date, row, slot);

            // var start = date.clone().add(slot * vm.duration, 'minutes').add(vm.after, 'hours');
            // var end = start.clone().add(vm.duration, 'minutes');

            // console.log('start', start)
            // console.log('end', end)

            vm.onSelect({
                index: vm.index,
                start: slot.start,
                end: slot.end
            });
        };
    }
})();