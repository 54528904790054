'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('vehicle').config(config);

    function config($stateProvider) {
        $stateProvider.state('vehicles', {
            url: '/vehicles',
            templateUrl: 'app/models/vehicles/vehicles.html',
            controller: 'VehiclesCtrl',
            controllerAs: 'vm'
        }).state('vehicle', {
            url: '/vehicle',
            templateUrl: 'app/models/vehicles/vehicle.html',
            controller: 'VehicleCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();