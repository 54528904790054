'use strict';

(function () {
    'use strict';

    angular.module('documentRequests').controller('DocumentRequestsCtrl.export', Ctrl);

    function Ctrl(vm, moment) {

        vm.export = function () {

            console.log('in export');

            var data = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = vm.rawData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var documentRequest = _step.value;


                    try {

                        data.push({

                            'Nom': documentRequest.name

                        });
                    } catch (error) {
                        console.log('depa export error', depa, error);
                    }
                }

                /* generate a worksheet */
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var ws = XLSX.utils.json_to_sheet(data);

            var columnsWidth = [];

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var key = _step2.value;

                    columnsWidth.push({ wch: key.length + 2 });
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            ws["!cols"] = columnsWidth;

            /* add to workbook */
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sinistres");

            /* write workbook (use type 'binary') */
            var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

            /* generate a download */
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) {
                    view[i] = s.charCodeAt(i) & 0xFF;
                }return buf;
            }

            saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export rapports.xlsx");
        };
    }
})();