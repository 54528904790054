'use strict';

/**
 * @author v.lugovksy
 * created on 16.12.2015
 */
(function () {
    'use strict';

    angular.module('common').directive('popvaletSlotFlexboxPicker', popvaletSlotPicker);

    /** @ngInject */
    function popvaletSlotPicker() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directive/slotPicker/slotPickerFlexBox.html',
            controller: SlotPickerCtrl,
            controllerAs: 'vm',
            bindToController: true,
            require: 'ngModel',
            scope: {
                ngModel: '=',
                first: '='
            }
        };
    }

    function SlotPickerCtrl($scope, $element, $attrs) {

        var vm = this;

        vm.currentFirstDate = new Date();

        $scope.$watch(angular.bind(this, function () {
            return this.first;
        }), function (value) {

            console.log('Name change to ' + value);

            if (value) {
                vm.currentFirstDate = new Date(value);
                vm.fillDays();
            }
        });

        vm.fillDays = function () {
            vm.days = [];

            for (var indexDay = 0; indexDay < 7; indexDay++) {
                var oneDate = new Date(vm.currentFirstDate);
                oneDate.setDate(oneDate.getDate() + indexDay);

                vm.days.push(oneDate);
            }

            vm.slots = [];

            for (var hour = 8; hour < 24; hour++) {
                vm.slots.push({ name: hour + ':00 - ' + hour + ':30', days: [0, 0, 0, 0, 0, 0, 0] });
                vm.slots.push({ name: hour + ':30 - ' + (hour + 1) + ':00', days: [0, 0, 0, 0, 0, 0, 0] });
            }
        };

        vm.fillDays();

        vm.shiftLeft = function () {
            //console.log('left')
            vm.currentFirstDate.setDate(vm.currentFirstDate.getDate() - 7);

            if (vm.currentFirstDate < vm.first || vm.currentFirstDate < new Date()) {
                if (vm.first) {
                    vm.currentFirstDate = new Date(vm.first);
                } else {
                    vm.currentFirstDate = new Date();
                }
            }

            vm.fillDays();
        };

        vm.shiftRight = function () {
            //console.log('right')
            vm.currentFirstDate.setDate(vm.currentFirstDate.getDate() + 7);
            vm.fillDays();
        };

        vm.selectSlot = function (row, column) {
            console.log('select ' + row + ' ' + column);

            if (vm.days[column].getDay() != 0) {
                if (typeof vm.previousRow != 'undefined' && typeof vm.previousColumn != 'undefined') {
                    vm.slots[vm.previousRow].days[vm.previousColumn] = '0';
                }

                vm.slots[row].days[column] = '1';

                vm.previousRow = row;
                vm.previousColumn = column;

                vm.start = new Date(vm.days[column]);

                vm.start.setHours(8 + row / 2);
                vm.start.setMinutes(row % 2 ? 30 : 0);
                vm.start.setSeconds(0);

                //console.log('start=',vm.start)


                vm.end = new Date(vm.start);
                vm.end.setMinutes(vm.end.getMinutes() + 30);

                //console.log('end=',vm.end)

                vm.ngModel = { start: vm.start, end: vm.end };
            }
        };
    }
})();