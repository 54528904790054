'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('documentRequests').config(config);

    function config($stateProvider) {
        $stateProvider.state('documentRequests', {
            url: '/documentRequests',
            templateUrl: 'app/pages/documentRequests/documentRequests.html',
            controller: 'DocumentRequestsCtrl',
            controllerAs: 'vm'
        }).state('documentRequest', {
            url: '/documentRequest/:id',
            templateUrl: 'app/pages/documentRequests/documentRequest.html',
            controller: 'DocumentRequestCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();