"use strict";

angular.module("common").controller("SelectController", function ($scope, CustomersService, ModalService) {
  $scope.refresh = function (newObj) {
    $scope.options.templateOptions.refresh().then(function (values) {
      $scope.to.options = values;

      if (newObj) {
        $scope.model[$scope.options.key] = "$" + newObj.id;
      }
    });
  };

  $scope.create = function () {
    console.log("$scope", $scope);

    ModalService.open($scope.options.templateOptions.addPage, {}).then(function (newObj) {
      $scope.refresh(newObj);
    });
  };

  $scope.reset = function () {
    console.log("reset");

    $scope.model[$scope.options.key] = null;

    if ($scope.options.templateOptions.refresh) {
      $scope.options.templateOptions.refresh();
    }
  };

  $scope.edit = function () {
    // console.log('edit', $scope.model[$scope.options.key])
    // ModalService.open('users/user', { position: position, id: $scope.model[$scope.options.key].substring(1) })
    //     .then(function(user) {
    //         console.log('close open user =', user)
    //         $scope.refresh(user)
    //     })
  };

  $scope.refresh();
});