'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {

    var moduleName = 'common';
    var componentName = 'modalComponent';

    var bindings = {
        modalInstance: '<',
        resolve: '<'
    };

    var templateUrl = 'app/common/component/modal/modal.component.html';

    var Controller = function () {
        /** @ngInject */
        function Controller($log, $scope, $compile, $element, $timeout) {
            _classCallCheck(this, Controller);

            this.log = debug('component:modal');

            this.$scope = $scope;
            this.$element = $element;
            this.$timeout = $timeout;
            this.$log = $log;
            this.$compile = $compile;
        }

        _createClass(Controller, [{
            key: '$onInit',
            value: function $onInit() {

                this.initModal();
                this.initCloseButton();
            }
        }, {
            key: 'camelCaseToDash',
            value: function camelCaseToDash(myStr) {
                return !myStr ? null : myStr.replace(/([A-Z])/g, function (g) {
                    return '-' + g[0].toLowerCase();
                });
            }
        }, {
            key: 'initModal',
            value: function initModal() {
                var _this = this;

                this.log('initModal =', this);

                if (this.resolve && this.resolve.bindings && this.resolve.bindings.component) {
                    var bindingsString = function bindingsString(bindings) {
                        var str = "";
                        if (bindings) {
                            for (var property in bindings) {
                                if (bindings.hasOwnProperty(property)) {
                                    str += " " + _this.camelCaseToDash(property) + "=\"$ctrl." + property + "\"";
                                    _this[property] = bindings[property];
                                }
                            }
                        }
                        return str;
                    };

                    var completeString = '<' + this.resolve.bindings.component + bindingsString(this.resolve.bindings) + ('><' + this.resolve.bindings.component + '>');
                    var compiledHtml = this.$compile(completeString)(this.$scope);

                    this.log('compiledHtml', completeString);
                    this.log('modal this', this);
                    $('#tb-modal').html(compiledHtml);
                } else {
                    this.$log.error("Modal not correctly initialized");
                }
            }
        }, {
            key: 'initCloseButton',
            value: function initCloseButton() {

                this.style = {
                    position: 'absolute',
                    top: '-20px',
                    right: '8px',
                    'font-size': 35 + 'px',
                    'z-index': 10
                };
            }
        }, {
            key: 'cancel',
            value: function cancel() {
                this.modalInstance.dismiss();
            }
        }, {
            key: 'done',
            value: function done() {

                this.log('this modal after done', Object.keys(this.resolve.bindings));

                var values = _.pick(this, Object.keys(this.resolve.bindings));

                this.modalInstance.close(values);
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();