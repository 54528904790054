'use strict';

(function () {
    'use strict';

    routerConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
    angular.module('app').config(routerConfig);

    function routerConfig($stateProvider, $urlRouterProvider) {

        var log = debug('config:index');

        log('enter');

        $urlRouterProvider.otherwise('home');
    }
})();