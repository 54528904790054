'use strict';

(function () {
    'use strict';

    angular.module('home').controller('HomeCtrl', HomeCtrl);

    function HomeCtrl($rootScope) {
        var vm = this;
        vm.ctrlName = 'HomeCtrl';
        vm.$version = $rootScope.$version;
    }
})();