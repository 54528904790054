'use strict';

/**
 * Created by sguilly on 19/10/16.
 */

(function () {
    'use strict';

    angular.module('common').directive('tagsDisplay', tagsDisplay);

    function tagsDisplay() {
        return {
            restrict: 'E',
            scope: {
                tags: '='
            },
            template: '<div class="tags-display-container">' + '<div ng-repeat="(idx, tag) in tags" class="tags-display-item"> {{tag}} </div>' + '</div>',
            link: function link($scope, $element) {}
        };
    }
})();