'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('insurerContracts').config(config);

    function config($stateProvider) {
        $stateProvider.state('insurerContracts', {
            url: '/insurerContracts',
            templateUrl: 'app/pages/insurerContracts/insurerContracts.html',
            controller: 'InsurerContractsCtrl',
            controllerAs: 'vm'
        }).state('insurerContract', {
            url: '/insurerContract/:id',
            templateUrl: 'app/pages/insurerContracts/insurerContract.html',
            controller: 'InsurerContractCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();