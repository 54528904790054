'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {

    var moduleName = 'common';
    var componentName = 'recurringEvent';

    var bindings = {
        rule: '=?',
        onChange: '&?'
    };

    var templateUrl = 'app/common/component/recurringEvent/recurringEventSelect.html';

    var Controller = function () {
        /** @ngInject */
        function Controller($log, $element, $timeout, $scope) {
            _classCallCheck(this, Controller);

            this.$element = $element;
            this.$timeout = $timeout;
            this.$scope = $scope;
        }

        _createClass(Controller, [{
            key: '$onInit',
            value: function $onInit() {

                this.init();
            }
        }, {
            key: 'init',
            value: function init() {
                var _this = this;

                this.initFrequencies();
                this.initWeekOrdinals();
                this.selectedMonthFrequency = 'day_of_month';
                this.resetData();

                //this.$scope.$watch(this.currentRule, this.ruleChanged);


                if (!_.isEmpty(this.rule)) {
                    console.log('rule not empty', this.rule);

                    this.$timeout(function () {

                        _this.parseRule(_this.rule.rrule);

                        if (_this.rule.until) {
                            _this.endDate = new Date(_this.rule.until);
                        }

                        // this.selectedFrequency = this.findFrequency(this.rule.frequencyName)
                        // console.log('selectedFrequency', this.selectedFrequency)
                        _this.calculateRRule();
                    });
                } else {
                    this.selectedFrequency = this.frequencies[0];
                    this.calculateRRule();
                }
            }
        }, {
            key: 'initFrequencies',
            value: function initFrequencies() {
                this.frequencies = [{ name: 'Jamais', type: 'none' }, { name: 'Chaque jour', rruleType: RRule.DAILY, type: 'day', display: 'jour' }, { name: 'Chaque semaine', rruleType: RRule.WEEKLY, type: 'week', display: 'semaine' }, { name: 'Chaque mois', rruleType: RRule.MONTHLY, type: 'month', display: 'mois' }];
            }
        }, {
            key: 'findFrequency',
            value: function findFrequency(name) {

                var frequency = this.frequencies.filter(function (el) {
                    return el.name === name;
                })[0];

                console.log('find for name', name, frequency);

                return frequency;
            }
        }, {
            key: 'initMonthlyDays',
            value: function initMonthlyDays() {
                var _this2 = this;

                this.monthDays = [];
                this.yearMonthDays = [];

                _.times(31, function (index) {
                    var day = { day: index + 1, value: index + 1, selected: false };
                    _this2.monthDays.push(day);
                    _this2.yearMonthDays.push(day);
                });
                var lastDay = { day: 'Le dernier jour du mois', value: -1, selected: false };
                this.monthDays.push(lastDay);
                this.yearMonthDays.push(lastDay);
            }
        }, {
            key: 'initWeekOrdinals',
            value: function initWeekOrdinals() {
                this.weekOrdinals = ['st', 'nd', 'rd', 'th'];
            }
        }, {
            key: 'initMonthlyWeeklyDays',
            value: function initMonthlyWeeklyDays() {
                var _this3 = this;

                this.monthWeeklyDays = [];

                _.times(4, function (index) {
                    var days = _.map(_this3.daysOfWeek(), function (dayOfWeek) {
                        dayOfWeek.value = dayOfWeek.value.nth(index + 1);
                        return dayOfWeek;
                    });
                    _this3.monthWeeklyDays.push(days);
                });
            }
        }, {
            key: 'changeType',
            value: function changeType() {

                console.log('changeType');
                this.resetData();
                this.calculateRRule();
            }
        }, {
            key: 'resetData',
            value: function resetData() {
                this.weekDays = this.daysOfWeek();
                this.initMonthlyDays();
                this.initMonthlyWeeklyDays();
                this.initYearlyMonths();
                this.selectedYearMonth = 1;
                this.selectedYearMonthDay = 1;
                this.interval = '';
            }
        }, {
            key: 'daysOfWeek',
            value: function daysOfWeek() {
                return [{ name: 'L', value: RRule.MO, selected: false }, { name: 'M', value: RRule.TU, selected: false }, { name: 'M', value: RRule.WE, selected: false }, { name: 'J', value: RRule.TH, selected: false }, { name: 'V', value: RRule.FR, selected: false }, { name: 'S', value: RRule.SA, selected: false }, { name: 'D', value: RRule.SU, selected: false }];
            }
        }, {
            key: 'initYearlyMonths',
            value: function initYearlyMonths() {
                this.yearMonths = [{ name: 'Jan', value: 1, selected: false }, { name: 'Feb', value: 2, selected: false }, { name: 'Mar', value: 3, selected: false }, { name: 'Apr', value: 4, selected: false }, { name: 'May', value: 5, selected: false }, { name: 'Jun', value: 6, selected: false }, { name: 'Jul', value: 7, selected: false }, { name: 'Aug', value: 8, selected: false }, { name: 'Sep', value: 9, selected: false }, { name: 'Oct', value: 10, selected: false }, { name: 'Nov', value: 11, selected: false }, { name: 'Dec', value: 12, selected: false }];
            }
        }, {
            key: 'selectMonthFrequency',
            value: function selectMonthFrequency(monthFrequency) {
                this.selectedMonthFrequency = monthFrequency;
                this.resetData();
                this.calculateRRule();
            }
        }, {
            key: 'toggleSelected',
            value: function toggleSelected(day) {
                day.selected = !day.selected;
                this.calculateRRule();
            }
        }, {
            key: 'calculateRRule',
            value: function calculateRRule() {

                console.log('switch période', this.selectedFrequency.type);

                switch (this.selectedFrequency.type) {
                    case 'none':
                        this.rule = null;
                        this.recurrenceRule = null;
                        break;
                    case 'day':
                        this.calculateDailyRRule();
                        break;
                    case 'week':
                        this.calculateWeeklyRRule();
                        break;
                    case 'month':
                        this.calculateMonthlyRRule();
                        break;
                    case 'year':
                        this.calculateYearlyRRule();
                }

                if (this.recurrenceRule) {

                    this.rule = {
                        rrule: 'RRULE:' + this.recurrenceRule.toString(),
                        description: this.recurrenceRule.toText('FRENCH'),
                        until: this.endDate,
                        frequencyName: this.selectedFrequency.name
                    };

                    if (this.onChange) {
                        this.onChange({ value: this.rule });
                    }
                }

                this.test();

                // if (!_.isUndefined(this.rule))
                //     this.rule = this.recurrenceRule.toString();
            }
        }, {
            key: 'onDate',
            value: function onDate(value) {

                console.log('date change !!!', value);
                this.endDate = value;
                this.calculateRRule();
            }
        }, {
            key: 'calculateInterval',
            value: function calculateInterval() {

                console.log('interval=', this.interval);
                var interval = parseInt(this.interval);
                if (!interval) interval = 1;

                console.log('interval=', interval);
                return interval;
            }
        }, {
            key: 'calculateDailyRRule',
            value: function calculateDailyRRule() {

                var options = {
                    freq: RRule.DAILY,
                    interval: this.calculateInterval(),
                    wkst: RRule.SU
                    //dtstart: new Date(),
                    // until: new Date(2012, 12, 31)
                };

                if (this.endDate && !isNaN(this.endDate.getTime())) {
                    options.until = this.endDate;
                }

                this.recurrenceRule = new RRule(options);

                console.log('create daily rule', this.recurrenceRule);
            }
        }, {
            key: 'calculateWeeklyRRule',
            value: function calculateWeeklyRRule() {
                var selectedDays = _.filter(this.weekDays, function (day) {
                    return day.selected;
                }).map(function (d) {
                    return d.value;
                });

                console.log('calculateWeeklyRRule selectedDays', selectedDays);

                this.recurrenceRule = new RRule({
                    freq: RRule.WEEKLY,
                    interval: this.calculateInterval(),
                    wkst: RRule.SU,
                    byweekday: selectedDays
                    //dtstart: new Date(),
                    // until: new Date(2012, 12, 31)
                });
            }
        }, {
            key: 'calculateMonthlyRRule',
            value: function calculateMonthlyRRule() {
                if (this.selectedMonthFrequency == 'day_of_month') this.calculateDayOfMonthRRule();else this.calculateDayOfWeekRRule();
            }
        }, {
            key: 'calculateDayOfMonthRRule',
            value: function calculateDayOfMonthRRule() {
                var selectedDays = _.filter(this.monthDays, function (day) {
                    return day.selected;
                }).map(function (d) {
                    return d.value;
                });

                this.recurrenceRule = new RRule({
                    freq: RRule.MONTHLY,
                    interval: this.calculateInterval(),
                    wkst: RRule.SU,
                    bymonthday: selectedDays
                    //dtstart: new Date(),
                    // until: new Date(2012, 12, 31)
                });
            }
        }, {
            key: 'calculateDayOfWeekRRule',
            value: function calculateDayOfWeekRRule() {
                var selectedDays = _(this.monthWeeklyDays).flatten().filter(function (day) {
                    return day.selected;
                }).map(function (d) {
                    return d.value;
                });

                this.recurrenceRule = new RRule({
                    freq: RRule.MONTHLY,
                    interval: this.calculateInterval(),
                    wkst: RRule.SU,
                    byweekday: selectedDays
                    //dtstart: new Date(),
                    // until: new Date(2012, 12, 31)
                });
            }
        }, {
            key: 'calculateYearlyRRule',
            value: function calculateYearlyRRule() {
                var selectedMonths = _(this.yearMonths).flatten().sortBy(function (month) {
                    return month.value;
                }).filter(function (month) {
                    return month.selected;
                }).map(function (d) {
                    return d.value;
                });

                var selectedDays = _(this.yearMonthDays).flatten().sortBy(function (day) {
                    return day.value;
                }).filter(function (day) {
                    return day.selected;
                }).map(function (d) {
                    return d.value;
                });

                this.recurrenceRule = new RRule({
                    freq: RRule.YEARLY,
                    interval: this.calculateInterval(),
                    bymonth: selectedMonths,
                    bymonthday: selectedDays
                    //dtstart: new Date(),
                    // until: new Date(2012, 12, 31)
                });
            }
        }, {
            key: 'parseRule',
            value: function parseRule(rRuleString) {
                var _this4 = this;

                this.recurrenceRule = RRule.rrulestr(rRuleString); //RRule.fromString(rRuleString);

                console.log('parse =', rRuleString, this.recurrenceRule);

                this.interval = this.recurrenceRule.options.interval;

                this.endDate = this.recurrenceRule.options.until;

                this.selectedFrequency = _.filter(this.frequencies, function (frequency) {
                    return frequency.rruleType == _this4.recurrenceRule.options.freq;
                })[0];

                switch (this.selectedFrequency.type) {
                    case 'week':
                        this.initFromWeeklyRule();
                    case 'month':
                        this.initFromMonthlyRule();
                }
            }
        }, {
            key: 'initFromWeeklyRule',
            value: function initFromWeeklyRule() {
                var ruleSelectedDays = this.recurrenceRule.options.byweekday;

                _.each(this.weekDays, function (weekDay) {
                    if (_.includes(ruleSelectedDays, weekDay.value.weekday)) weekDay.selected = true;
                });
            }
        }, {
            key: 'initFromMonthlyRule',
            value: function initFromMonthlyRule() {
                if (!_.isEmpty(this.recurrenceRule.options.bymonthday) || !_.isEmpty(this.recurrenceRule.options.bynmonthday)) this.initFromMonthDays();else if (!_.isEmpty(this.recurrenceRule.options.bynweekday)) this.initFromMonthWeekDays();
            }
        }, {
            key: 'initFromMonthDays',
            value: function initFromMonthDays() {
                var ruleMonthDays = this.recurrenceRule.options.bymonthday;
                this.selectedMonthFrequency = 'day_of_month';

                _.each(this.monthDays, function (weekDay) {
                    if (_.includes(ruleMonthDays, weekDay.value)) weekDay.selected = true;
                });

                if (this.recurrenceRule.options.bynmonthday.length > 0 && this.recurrenceRule.options.bynmonthday[0] == -1) this.monthDays[31].selected = true;
            }
        }, {
            key: 'initFromMonthWeekDays',
            value: function initFromMonthWeekDays() {
                var ruleWeekMonthDays = this.recurrenceRule.options.bynweekday;
                this.selectedMonthFrequency = 'day_of_week';

                _.each(ruleWeekMonthDays, function (ruleArray) {
                    var dayIndex = ruleArray[0];
                    var weekIndex = ruleArray[1] - 1;

                    var week = this.monthWeeklyDays[weekIndex];
                    _.each(week, function (day) {
                        if (day.value.weekday == dayIndex) {
                            day.selected = true;
                            return;
                        }
                    });
                });
            }
        }, {
            key: 'ruleChanged',
            value: function ruleChanged() {
                if (!_.isEmpty(this.rule)) {
                    this.parseRule(this.rule);
                }
            }
        }, {
            key: 'currentRule',
            value: function currentRule() {
                return this.rule ? this.rule : null;
            }
        }, {
            key: 'test',
            value: function test() {

                console.log('test');

                if (this.recurrenceRule) {

                    console.log('test the rule', this.rule);

                    var rule = RRule.rrulestr('RRULE:' + this.recurrenceRule.toString());

                    rule.dtstart = new Date();

                    var events = rule.between(new Date(), new Date(2018, 1, 1));

                    var eventsArray = [];

                    for (var index = 0; index < events.length; index++) {
                        var event = events[index];

                        eventsArray.push({ start: event });
                    }

                    this.events = eventsArray;
                }
            }
        }]);

        return Controller;
    }();

    angular.module(moduleName).component(componentName, {
        templateUrl: templateUrl,
        bindings: bindings,
        transclude: true,
        controller: Controller
    });
})();