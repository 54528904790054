/**
 * Created by sguilly on 31/10/16.
 */
"use strict";

angular.module("pages").service("ModelsService", ["$rootScope", "hotkeys", function ($rootScope, hotkeys) {
  this.nbTableLine = 10;

  this.setServerUrl = function (url) {
    this.serverUrl = url;
  };

  this.onCustomerChange = function (cb) {
    var _console;

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    (_console = console).log.apply(_console, ["cb"].concat(args));
  };

  this.hotkeys = function (scope, combo, description, cb) {
    for (var _len2 = arguments.length, args = Array(_len2 > 4 ? _len2 - 4 : 0), _key2 = 4; _key2 < _len2; _key2++) {
      args[_key2 - 4] = arguments[_key2];
    }

    hotkeys.bindTo(scope).add({
      combo: combo,
      description: description,
      callback: function callback() {
        cb.apply(undefined, args);
      }
    });
  };
}]);