'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('groups').config(config);

    function config($stateProvider) {
        $stateProvider.state('groups', {
            url: '/groups',
            templateUrl: 'app/pages/groups/groups.html',
            controller: 'GroupsCtrl',
            controllerAs: 'vm'
        }).state('group', {
            url: '/group/:id',
            templateUrl: 'app/pages/groups/group.html',
            controller: 'GroupCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();