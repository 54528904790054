'use strict';

(function () {
    'use strict';

    config.$inject = ['$stateProvider'];
    angular.module('customerCommunications').config(config);

    function config($stateProvider) {
        $stateProvider.state('customerCommunications', {
            url: '/customerCommunications',
            templateUrl: 'app/pages/customerCommunications/customerCommunications.html',
            controller: 'CustomerCommunicationsCtrl',
            controllerAs: 'vm'
        }).state('customerCommunication', {
            url: '/customerCommunication/:id',
            templateUrl: 'app/pages/customerCommunications/customerCommunication.html',
            controller: 'CustomerCommunicationCtrl',
            controllerAs: 'vm',
            resolve: {
                params: function params() {
                    return {};
                }
            }
        });
    }
})();